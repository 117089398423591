// 차량판매 페이지 utils

export const bikeList = [
  {
    url: '/sale/detail/VSX_125',
    img: '/images/sale/img_VSX_125.png',
    title: 'VSX 125',
    price: '3,980,000',
  },
  {
    url: '/sale/detail/VS_125',
    img: '/images/sale/img_VS125.png',
    title: 'VS 125',
    price: '4,790,000',
  },
  {
    url: '/sale/detail/VS_300',
    img: '/images/sale/img_VS300.png',
    title: 'VS 300',
    price: '5,990,000',
  },
  // {
  //   url: '/sale/detail/Fiddle_125',
  //   img: '/images/sale/img_Fiddle_125.png',
  //   title: 'Fiddle 125',
  //   price: '3,690,000',
  // },
  {
    url: '/sale/detail/S5',
    img: '/images/sale/img_S5.png',
    title: 'S5',
    price: '990,000',
  },
  {
    url: '/sale/detail/S3',
    img: '/images/sale/img_S3.png',
    title: 'S3',
    price: '1,090,000',
  },
];

// 차량판매 페이지 03번
export const serviceList = [
  {
    id: 1,
    title: '무상점검',
    desc: '주행 거리별\n무상 점검 제공',
    mobile_desc: '주행 거리별 무상 점검 제공',
  },
  {
    id: 2,
    title: '차량수리',
    desc: '보증수리/일반수리\n소모품 교환 및 차량 관리',
    mobile_desc: '보증수리/일반수리\n소모품 교환 및 차량 관리',
  },
  {
    id: 3,
    title: '순정부품',
    desc: '원할한 정비를 위해\n순정부품 공급 및 재고의 확보',
    mobile_desc: '원할한 정비를 위해\n순정부품 공급 및 재고의 확보',
  },
  {
    id: 4,
    title: '멤버십 혜택',
    desc: '전용 앱을 통한 편리한 차량관리\n이벤트 쿠폰 제공 예정',
    mobile_desc: '전용 앱을 통한 편리한 차량관리\n이벤트 쿠폰 제공 예정',
  },
];

export const detailIntroList = [
  {
    title: 'VSX_125',
    desc: '시선을 사로 잡는 디자인',
    price: '3,980,000 원',
    design_desc01: '2채널 ABS로 더 안정된 제동력',
    design_desc02: '모든 등화기 LED 라이트 적용',
    design_desc03: '편안한 라이딩 포지션',
    detail_title01: '국내 단독 판매 VSX 125',
    detail_title02: '디테일의 변화로 향상된 완성도',
    option_list01: [
      {
        title: '엔진형식',
        desc: '수랭4스트로크 단기통',
      },
      {
        title: '배기량',
        desc: '124.6cc',
      },
      {
        title: '최고출력',
        desc: '12.4ps/8,000rpm',
      },
      {
        title: '최대토크',
        desc: '11.0Nm/6,500rpm',
      },
    ],
    option_list02: [
      {
        title: '전장',
        desc: '2,000',
      },
      {
        title: '전폭',
        desc: '760',
      },
      {
        title: '시트고',
        desc: '770',
      },
      {
        title: '차량중량',
        desc: '140kg',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587', '#c40f31', '#5a3d23'],
    swiper: ['/images/sale/detail/img_swiper_VSX_125_01.png', '/images/sale/detail/img_swiper_VSX_125_02.png', '/images/sale/detail/img_swiper_VSX_125_03.png', '/images/sale/detail/img_swiper_VSX_125_04.png', '/images/sale/detail/img_swiper_VSX_125_05.png'],
    gallery: ['/images/sale/detail/img_gallery_VSX_125_01.png', '/images/sale/detail/img_gallery_VSX_125_02.png', '/images/sale/detail/img_gallery_VSX_125_03.png', '/images/sale/detail/img_gallery_VSX_125_04.png', '/images/sale/detail/img_gallery_VSX_125_05.png', '/images/sale/detail/img_gallery_VSX_125_06.png'],
  },
  {
    title: 'VS_300',
    desc: '편안한 도시형 빅스쿠터',
    price: '5,990,000 원',
    design_desc01: '시인성 넓힌 LED 라이트',
    design_desc02: '클래식하고 심플한 계기판',
    design_desc03: '넓은 수납 공간',
    detail_title01: '넓은 시트와 트렁크 공간',
    detail_title02: '라이딩 포지션이 편안한 빅스쿠터',
    option_list01: [
      {
        title: '엔진형식',
        desc: '수랭4스트로크 단기통',
      },
      {
        title: '배기량',
        desc: '278.3cc',
      },
      {
        title: '최고출력',
        desc: '24.6ps/7,500rpm',
      },
      {
        title: '최대토크',
        desc: '2.67kgf-m/6,750rpm',
      },
    ],
    option_list02: [
      {
        title: '전장',
        desc: '2,160',
      },
      {
        title: '전폭',
        desc: '760',
      },
      {
        title: '높이',
        desc: '1,390',
      },
      {
        title: '차량중량',
        desc: '195kg',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587'],
    swiper: ['/images/sale/detail/img_swiper_VS_300_01.png', '/images/sale/detail/img_swiper_VS_300_02.png', '/images/sale/detail/img_swiper_VS_300_03.png', '/images/sale/detail/img_swiper_VS_300_04.png', '/images/sale/detail/img_swiper_VS_300_05.png'],
    gallery: ['/images/sale/detail/img_gallery_VS_300_01.png', '/images/sale/detail/img_gallery_VS_300_02.png', '/images/sale/detail/img_gallery_VS_300_03.png', '/images/sale/detail/img_gallery_VS_300_04.png', '/images/sale/detail/img_gallery_VS_300_05.png', '/images/sale/detail/img_gallery_VS_300_06.png'],
  },
  {
    title: 'VS_125',
    desc: '편안한 도시형 빅스쿠터',
    price: '4,790,000 원',
    design_desc01: '시인성 넓힌 LED 라이트',
    design_desc02: '클래식하고 심플한 계기판',
    design_desc03: '넓은 수납 공간',
    detail_title01: '스탠다드 스쿠터의 기준',
    detail_title02: '조용한 엔진음에서 나오는 강력한 파워',
    option_list01: [
      {
        title: '엔진형식',
        desc: '수랭4스트로크 단기통',
      },
      {
        title: '배기량',
        desc: '124.9cc',
      },
      {
        title: '최고출력',
        desc: '13.3ps/9,000rpm',
      },
      {
        title: '최대토크',
        desc: '1.15kgf-m/6,750rpm',
      },
    ],
    option_list02: [
      {
        title: '전장',
        desc: '2,160',
      },
      {
        title: '전폭',
        desc: '760',
      },
      {
        title: '높이',
        desc: '1,390',
      },
      {
        title: '차량중량',
        desc: '175kg',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587'],
    swiper: ['/images/sale/detail/img_swiper_VS_125_01.png', '/images/sale/detail/img_swiper_VS_125_02.png', '/images/sale/detail/img_swiper_VS_125_03.png', '/images/sale/detail/img_swiper_VS_125_04.png', '/images/sale/detail/img_swiper_VS_125_05.png'],
    gallery: ['/images/sale/detail/img_gallery_VS_300_01.png', '/images/sale/detail/img_gallery_VS_300_02.png', '/images/sale/detail/img_gallery_VS_300_03.png', '/images/sale/detail/img_gallery_VS_300_04.png', '/images/sale/detail/img_gallery_VS_300_05.png', '/images/sale/detail/img_gallery_VS_300_06.png'],
  },
  {
    title: 'Fiddle_125',
    desc: '나를 위한 선택',
    price: '3,690,000 원',
    design_desc01: '보다 편리해진 디지털 계기판',
    design_desc02: '시인성을 높인 전면 LED 헤드라이트',
    design_desc03: '2채널 ABS로 더 안정된 제동력',
    detail_title01: '도심 속을 누비는 클래식',
    detail_title02: '디자인과 실용성을 겸비한 스쿠터',
    option_list01: [
      {
        title: 'Euro 5',
        desc: '',
      },
      {
        title: '배기량',
        desc: '124cc',
      },
      {
        title: '최고출력',
        desc: '11.4ps/8,500rpm',
      },
      {
        title: '최대토크',
        desc: '10.3Nm/6,500rpm',
      },
    ],
    option_list02: [
      {
        title: '전장',
        desc: '1,880',
      },
      {
        title: '전폭',
        desc: '675',
      },
      {
        title: '휠베이스',
        desc: '1,115',
      },
      {
        title: '차량중량',
        desc: '125kg',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587', '#366297'],
    swiper: ['/images/sale/detail/img_swiper_Fiddle_125_01.png', '/images/sale/detail/img_swiper_Fiddle_125_02.png', '/images/sale/detail/img_swiper_Fiddle_125_03.png', '/images/sale/detail/img_swiper_Fiddle_125_04.png', '/images/sale/detail/img_swiper_Fiddle_125_05.png'],
    gallery: ['/images/sale/detail/img_gallery_Fiddle_125_01.png', '/images/sale/detail/img_gallery_Fiddle_125_02.png', '/images/sale/detail/img_gallery_Fiddle_125_03.png', '/images/sale/detail/img_gallery_Fiddle_125_04.png', '/images/sale/detail/img_gallery_Fiddle_125_05.png', '/images/sale/detail/img_gallery_Fiddle_125_06.png'],
  },
  {
    title: 'S5',
    desc: '근거리 교통의 최적화',
    price: '1,390,000 원',
    design_desc01: '장시간 라이딩을 보호해주는 서스펜션',
    design_desc02: '간단하게 분리하여 충전하는 싯포스트 배터리',
    design_desc03: '안전을 지켜주는 디스크 브레이크',
    detail_title01: 'PAS, 스로틀 방식 전기자전거',
    detail_title02: '타고 내리기 편리한 유선형 프레임',
    option_list01: [
      {
        title: '모터전원',
        desc: '350W',
      },
      {
        title: '용량/전압',
        desc: '10Ah,36V',
      },
      {
        title: '중량',
        desc: '19kg',
      },
      {
        title: '방수등급',
        desc: 'IP54',
      },
    ],
    option_list02: [
      {
        title: '주행거리',
        desc: '25km/h / 최대 100km',
      },
      {
        title: '충전시간',
        desc: '5시간',
      },
      {
        title: '사이즈',
        desc: '1600X600X1160mm',
      },
      {
        title: '폴딩사이즈',
        desc: '830X530X770mm',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587', '#c40f31', '#ffce30'],
    swiper: ['/images/sale/detail/img_swiper_S5_01.png', '/images/sale/detail/img_swiper_S5_02.png', '/images/sale/detail/img_swiper_S5_03.png', '/images/sale/detail/img_swiper_S5_04.png'],
  },
  {
    title: 'S3',
    desc: '세련된 이동 수단',
    price: '1,690,000 원',
    design_desc01: '2채널 ABS로 더 안정된 제동력',
    design_desc02: '강렬한 전면부와 강력해진 밝기',
    design_desc03: '편안한 라이딩 포지션',
    mobile_design_desc01: '야간에도 주행이 가능한 전방 램프',
    mobile_design_desc02: '쉽게 보관할 수 있는 폴딩 시스템',
    mobile_design_desc03: '탈부착이 편리한 싯포스트 배터리',
    option_list01: [
      {
        title: '모터전원',
        desc: '350W',
      },
      {
        title: '용량/전압',
        desc: '10Ah,36V',
      },
      {
        title: '중량',
        desc: '21kg',
      },
      {
        title: '방수등급',
        desc: 'IP54',
      },
    ],
    option_list02: [
      {
        title: '주행거리',
        desc: '25km/h / 최대 100km',
      },
      {
        title: '충전시간',
        desc: '3.5~5시간',
      },
      {
        title: '사이즈',
        desc: '1510X600X1100mm',
      },
      {
        title: '폴딩사이즈',
        desc: '840X500X770mm',
      },
    ],
    color: ['#f5f5f5', '#020202', '#828587', '#c40f31', '#ffce30'],
    detail_title01: '미니멀리즘한 조화로운 디자인',
    detail_title02: 'PAS+스로틀 두 가지 주행 스타일',
    swiper: ['/images/sale/detail/img_swiper_S3_01.png', '/images/sale/detail/img_swiper_S3_02.png', '/images/sale/detail/img_swiper_S3_03.png', '/images/sale/detail/img_swiper_S3_04.png'],
  },
];
