/*** 회사명 ***/
export const COMPANY_NAME_KO = '바이크뱅크';
export const COMPANY_NAME_EN = 'BIKE BANK';

/*** 대표자명 ***/
export const CEO_NAME = '이윤조';

/*** 주소 ***/
export const COMPANY_ADDRESS = '대구광역시 달서구 성서공단로 11길 62, 5층';

/*** 전화번호 ***/
export const COMPANY_CALL = '1522-9008';
