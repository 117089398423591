import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mobileContext } from '../../utils/mobileContext';

const GoTop = () => {
  // 사용자의 스크롤 위치
  const { scrollPosition } = useContext(mobileContext);
  // 현재 최상단인지 여부
  const [isTop, setIsTop] = useState(true);
  // 눌렀을때 가장 최 상단으로 이동시키는 함수
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  // 현재 위치에 따라 최상단인지 여부를 저장한다.
  useEffect(() => {
    scrollPosition <= 0 || scrollPosition === undefined ? setIsTop(true) : setIsTop(false);
  }, [scrollPosition]);

  return <Wrap onClick={handleScrollTop} isTop={isTop}></Wrap>;
};

export default GoTop;

const Wrap = styled.button`
  width: 60px;
  height: 60px;
  background: url('/images/go_top.png') no-repeat center;
  background-size: contain;
  position: fixed;
  right: 2.5%;
  bottom: 60px;
  z-index: 9;
  opacity: 0;
  transition: all 0.5s ease;
  ${(props) =>
    !props.isTop &&
    css`
      opacity: 1;
    `}
`;
