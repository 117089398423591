import styled, { css } from 'styled-components';

export const BikeList = styled.ul`
  display: flex;
  margin-top: 98px;
  flex-wrap: wrap;
  gap: 27px;
  li {
    cursor: pointer;
    &:hover {
      .imgbox {
        img {
          transform: scale(1.05);
          transition: all 0.5s;
        }
      }
    }
    .imgbox {
      width: 380px;
      height: 350px;
      border-radius: 15px;
      background-color: #f8f8f8;
      box-shadow: 5px 0 30px 0 rgba(225, 225, 225, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        transition: all 0.5s;
      }
    }
    p {
      margin-bottom: calc(44px - 27px);
      text-align: center;
      padding-top: 20px;
      color: #5d5d5d;
      font-size: 18px;
      line-height: 20px;
      font-weight: 300;
      letter-spacing: -0.55px;
      font-weight: 400;
      font-family: 'NanumSquare';
      span {
        display: block;
        line-height: 25px;
        color: #060606;
        font-weight: 600;
        font-size: 22px;
        padding-bottom: 5px;
        font-family: 'NanumSquare';
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.tablet}) {
    gap: 15px;
    justify-content: center;
  }
  @media screen and (max-width: 835px) {
    gap: 10px;
    justify-content: center;
    align-items: center;
    li {
      border-radius: 15px 15px 0 0;
      p {
        border-radius: 0 0 15px 15px;
        background-color: #f8f8f8;
        padding: 20px 0;
        margin: 0;
        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
`;

export const OptionList = styled.ul`
  display: flex;
  gap: 50px;
  /* margin-top: 20px; */
`;
export const Exp = styled.p`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.45px;
  color: #5d5d5d;
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: #5d5d5d;
    margin-top: 20px;
  }
`;
export const App = styled.div`
  background: url('/images/bg_app.png') no-repeat center;
  padding: 94px 0 81px 57px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  p {
    color: #fff;
    font-size: 32px;
    line-height: 45px;
    letter-spacing: -0.8px;
    span {
      font-weight: 700;
    }
  }
  .appcontainer {
    display: flex;
    gap: 13px;
    width: 100%;
    button {
      background-color: #fff;
      width: 150px;
      height: 44px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 72px;
        height: auto;
        &.google {
          max-width: 84px;
          height: auto;
        }
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    margin-right: 30px;
    padding: 22px 0 20px 20px;
    gap: 35px;
    margin-top: 20px;
    background: url('/images/mobile_background_appdown.png') no-repeat center;
    background-size: cover;
    height: 165px;
    p {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.45px;
      font-weight: 700;
    }
    .appcontainer {
      display: flex;
      gap: 14px;
      button {
        background-color: #fff;
        width: 105px;
        height: 31px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 6px;
        padding: 0 6px;
        img {
          width: 72px;
          height: auto;
          &.google {
            width: 75px;
          }
        }
      }
    }
  }
`;
export const ServiceWrap = styled.ul`
  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 20px;
    `}
`;
export const Button = styled.button`
  background-color: #434343;
  border: 1px solid #707070;
  border-radius: 32.5px;
  font-size: 19px;
  height: 65px;
  width: 227.5px;
  color: #f8f8f8;
  margin: 50px auto 0 auto;
  cursor: pointer;
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.56px;
    color: #5d5d5d;
    background-color: #fff;
    border: 1px solid #707070;
    width: 180px;
    height: 44px;
    padding-right: ${(props) => props.pr};
    img {
      max-width: 72px;
      height: auto;
    }
  }
`;
