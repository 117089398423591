import { createGlobalStyle } from 'styled-components';

import SFProDisplayRegular from './font/SFProDisplay-Regular.woff';
import SFProDisplayMedium from './font/SFProDisplay-Medium.woff';
import SFProDisplayBold from './font/SFProDisplay-Bold.woff';

import NotoSnasKRThin from './font/NotoSansKR-Thin.woff';
import NotoSnasKRLight from './font/NotoSansKR-Light.woff';
import NotoSnasKRRegular from './font/NotoSansKR-Regular.woff';
import NotoSnasKRMedium from './font/NotoSansKR-Medium.woff';
import NotoSnasKRBold from './font/NotoSansKR-Bold.woff';
import NotoSnasKRBlack from './font/NotoSansKR-Bold.woff';

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 100;
        src: url('./font/NotoSansKR-Thin.otf');
        src: url('./font/NotoSansKR-Thin.eot') format('embedded-opentype'),
        url(${NotoSnasKRThin}) format('woff'),
        url('./font/NotoSansKR-Thin.ttf') format('truetype');
    }

    @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 300;
        src: url('./font/NotoSansKR-Light.otf');
        src: url('./font/NotoSansKR-Light.eot') format('embedded-opentype'),
        url(${NotoSnasKRLight}) format('woff'),
        url('./font/NotoSansKR-Light.ttf') format('truetype');
    }
    @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 400;
        src: url('./font/NotoSansKR-Regular.otf');
        src: url('./font/NotoSansKR-Regular.eot') format('embedded-opentype'),
        url(${NotoSnasKRRegular}) format('woff'),
        url('./font/NotoSansKR-Regular.ttf') format('truetype');
    }

    @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 500;
        src: url('./font/NotoSansKR-Medium.otf');
        src: url('./font/NotoSansKR-Medium.eot') format('embedded-opentype'),
        url(${NotoSnasKRMedium}) format('woff'),
        url('./font/NotoSansKR-Medium.ttf') format('truetype');
    }

    @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 700;
        src: url('./font/NotoSansKR-Bold.otf');
        src: url('./font/NotoSansKR-Bold.eot') format('embedded-opentype'),
        url(${NotoSnasKRBold}) format('woff'),
        url('./font/NotoSansKR-Bold.ttf') format('truetype');
    }

        @font-face {
        font-family:'Noto-Sans-KR';
        font-weight: 900;
        src: url('./font/NotoSansKR-Black.otf');
        src: url('./font/NotoSansKR-Black.eot') format('embedded-opentype'),
        url(${NotoSnasKRBlack}) format('woff'),
        url('./font/NotoSansKR-Black.ttf') format('truetype');
    }





    @font-face {
    font-family: 'SF-Pro-Display';
    font-weight: 400;
    src: url('./font/SFProDisplay-Regular.otf');
    src: url('./font/SFProDisplay-Regular.eot') format('embedded-opentype'),
    url(${SFProDisplayRegular}) format('woff'),
    url('./font/SFProDisplay-Regular.ttf') format('truetype');
    }

        @font-face {
    font-family: 'SF-Pro-Display';
    font-weight: 600;
    src: url('./font/SFProDisplay-Medium.otf');
    src: url('./font/SFProDisplay-Medium.eot') format('embedded-opentype'),
    url(${SFProDisplayMedium}) format('woff'),
    url('./font/SFProDisplay-Medium.ttf') format('truetype');
    }

    @font-face {
    font-family: 'SF-Pro-Display';
    font-weight: bold;
    src: url('./font/SFProDisplay-Bold.otf');
    src: url('./font/SFProDisplay-Bold.eot') format('embedded-opentype'),
    url(${SFProDisplayBold}) format('woff'),
    url('./font/SFProDisplay-Bold.ttf') format('truetype');
    }



    // 나눔고딕
    @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        font-family: 'Noto-Sans-KR', sans-serif;
        ::selection {
            background-color: #ffce30;
            color: black;
        }
    }
    html,body {
        font-size: 16px;
    }
    a {
        cursor: pointer;
        text-decoration: none;
        color: black;
    }
    p,span,h1,h2,h3,h4,h5,h6 {
        cursor: default;
    }
    button{
        cursor: pointer;
        background: none;
        border: none;
    }
    img {
    image-rendering: -webkit-optimize-contrast !important;
    backface-visibility:hidden !important;
    }
`;

export default GlobalStyles;
