import React from "react";
import * as style from "./styles";
import * as component from "../../components";
import * as utils from "utils";
import { COMPANY_NAME_KO } from "../../utils/utilsConstant";

const Privacy = () => {
  return (
    <>
      {/* 메타태그 */}
      <component.MetaTag />
      <style.Wrap>
        <style.Title>{COMPANY_NAME_KO} 개인정보처리방침</style.Title>
        <p>
          &#60; {COMPANY_NAME_KO} 주식회사 &#62;('이하 '회사')은(는) 「개인정보
          보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한
          고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
          개인정보 처리방침을 수립·공개합니다.
        </p>
        <h1>제1조 개인정보 수집 및 이용</h1>
        <h1>제2조 개인정보의 제3자 제공</h1>
        <h1>제3조 개인정보의 처리위탁</h1>
        <h1>제4조 개인정보 국외 이전</h1>
        <h1>제5조 정보주체와 법정대리인의 권리 및 행사방법</h1>
        <h1>제6조 아동의 개인정보보호</h1>
        <h1>제7조 개인정보의 파기</h1>
        <h1>제8조 개인정보의 안전성 확보조치</h1>
        <h1>제9조 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항</h1>
        <h1>제10조 개인정보 보호책임자 및 열람 청구 담당부서</h1>
        <h1>제11조 정보주체의 권익침해에 대한 구제방법</h1>
        <h1>제12조 개인위치정보 처리에 관한 사항</h1>
        <h1>제13조 영상정보처리기기 운영·관리에 관한 사항</h1>
        <br />
        <h1>제1조 개인정보 수집 및 이용</h1>
        <p>
          회사는 개인정보 수집 시 필요한 시점에서 최소한의 개인정보만 수집하고
          있으며 최초 안내한 목적으로만 사용하고 있습니다.
        </p>

        {/* table */}
        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>구분 / 서비스명 </td>
              <td>목적</td>
              <td>수집항목</td>
              <td>보유기간</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>홈페이지</td>
              <td>문의 / 민원 답변 처리</td>
              <td>
                [필수항목]
                <br />
                성명, 생년월일, 휴대전화번호
                <br />
                [선택항목]
                <br />
                이메일
              </td>
              <td>회원 탈퇴 시까지</td>
            </tr>
            <tr>
              <td>고고로 홈페이지</td>
              <td>문의 / 민원 답변 처리</td>
              <td>
                [필수항목]
                <br />
                성명, 생년월일, 휴대전화번호
                <br />
                [선택항목]
                <br />
                이메일
              </td>
              <td>회원 탈퇴 시까지</td>
            </tr>
            <tr>
              <td>바이킹 홈페이지</td>
              <td>상품 구매 및 결제 / 문의 / 민원 답변 처리</td>
              <td>
                [필수항목]
                <br />
                성명, 생년월일, 휴대전화번호
                <br />
                [선택항목]
                <br />
                이메일
              </td>
              <td>회원 탈퇴 시까지</td>
            </tr>
            <tr>
              <td>바이킹 앱</td>
              <td>상품 구매 및 결제</td>
              <td>
                [필수항목]
                <br />
                성명, 생년월일, 휴대전화번호
                <br />
                [선택항목]
                <br />
                이메일
              </td>
              <td>회원 탈퇴 시까지</td>
            </tr>
            <tr>
              <td>바이킹 앱</td>
              <td>이벤트 정보 제공</td>
              <td>
                [필수항목]
                <br />
                휴대전화번호, 이메일
                <br />
                [선택항목]
                <br />
                성명, 생년월일
              </td>
              <td>회원 탈퇴 시 혹은 동의 철회 시까지</td>
            </tr>
            <tr>
              <td>BBM 앱</td>
              <td>출고 차량 관리</td>
              <td>
                [필수항목]
                <br />
                아이디, 비밀번호, 이름, 연락처
                <br />
                [선택항목]
                <br />
                상세주소, 이메일, 성별, 생년월일
              </td>
              <td>회원 탈퇴 시까지</td>
            </tr>
            <tr>
              <td>차량 공유 앱</td>
              <td>렌트 차량 관리</td>
              <td>
                [필수항목]
                <br />
                아이디, 비밀번호, 이름, 휴대전화, 지점명, 주소
                <br />
                [선택항목]
                <br />
                상세주소
              </td>
              <td>회원 탈퇴 시 혹은 렌트상품 이용 종료일 까지</td>
            </tr>
            <tr>
              <td>차량 주문 앱</td>
              <td>렌트 차량 주문</td>
              <td>
                [필수항목]
                <br />
                아이디, 비밀번호, 이름, 휴대전화, 지점명, 주소
                <br />
                [선택항목]
                <br />
                상세주소, 차량번호, 사업자등록번호
              </td>
              <td>회원 탈퇴 시 혹은 렌트상품 이용 종료일 까지</td>
            </tr>
            <tr>
              <td>소모품 쿠폰 앱</td>
              <td>소모품 교환 관리</td>
              <td>
                [필수항목]
                <br />
                SNS_ID, 성명, 휴대전화번호
                <br />
                [선택항목]
                <br />
                이메일
              </td>
              <td>회원 탈퇴 시 혹은 렌트상품 이용 종료일 까지</td>
            </tr>
            <tr>
              <td>GOGORO 앱</td>
              <td>
                전기이륜차량
                <br />
                플랫폼
              </td>
              <td>
                [필수항목]
                <br />
                성명, 생년월일
                <br />
                휴대전화번호, 차량정보, 결제정보
                <br />
                [선택항목] 이메일
              </td>
              <td>회원 탈퇴 시 혹은 상품 이용종료일 까지</td>
            </tr>
            <tr>
              <td>시승예약 시스템</td>
              <td>시승 예약</td>
              <td>[필수항목] 성명, 휴대전화번호</td>
              <td>회원 탈퇴 시 혹은 행사 종료일 까지</td>
            </tr>
          </tbody>
        </style.Table>
        <p>※ 관련 법령에 의해 처리하는 개인정보</p>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>관련법령</td>
              <td>목적</td>
              <td>항목</td>
              <td>처리 및 보유기간</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>전자상거래등에서의 소비자보호에 관한법률</td>
              <td>소비자의 불만 또는 분쟁처리 기록의 보관</td>
              <td>불만 또는 분쟁처리에 관한 기록</td>
              <td>3년</td>
            </tr>
            <tr>
              <td>전자상거래등에서의 소비자보호에 관한법률</td>
              <td>대금결제 및 재화 등의 공급에 관한 기록의 보관</td>
              <td>대금결제 및 재화 등의 공급에 관한 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>전자상거래등에서의 소비자보호에 관한법률</td>
              <td>청약철회 등에 관한 기록의 보관</td>
              <td>청약철회 등에 관한 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>국세기본법</td>
              <td>국세 부과를 위한 제척기간 계산</td>
              <td>과세표준과 세액의 신고자료</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>국세기본법</td>
              <td>국세징수권 등의 소멸시효 계산</td>
              <td>국세 증빙자료</td>
              <td>10 년</td>
            </tr>
            <tr>
              <td>통신비밀보호법</td>
              <td>수사기관이 요청 시 제공</td>
              <td>웹사이트 방문기록</td>
              <td>3 개월</td>
            </tr>
          </tbody>
        </style.Table>

        <h1>제2조 개인정보의 제3자 제공</h1>
        <p>
          ① 회사는 정보주체의 개인정보를 처리 목적에서 명시한 범위 내에서만
          처리하며, 정보주체의 동의, 법률의 특별한 규정 등에 해당하는 경우에만
          개인정보를 제 3 자에게 제공하고 있습니다.
          <br />② 회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를
          얻어 필요 최소한의 범위로만 제공합니다.
        </p>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>개인정보를 제공받는 자</td>
              <td>제공받는 자의 개인정보 이용목적</td>
              <td>제공하는 개인정보 항목</td>
              <td>제공받는 자의 보유 및 이용기간</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>이노페이(바이킹 PG사)</td>
              <td>바이킹 결제 중개</td>
              <td>성명, 생년월일, 전화번호, 결제정보</td>
              <td rowSpan="5">
                이용 목적 달성 후 즉시 파기 혹은 관련 법령에 따른 보존기간
              </td>
            </tr>
            <tr>
              <td>비앤에프소프트</td>
              <td>UNIERP 개발</td>
              <td>거래처 정보</td>
            </tr>
            <tr>
              <td>체큐리티</td>
              <td>개러지 카드 결제 중개</td>
              <td>성명, 생년월일, 전화번호, 결제정보</td>
            </tr>
            <tr>
              <td>비비인스 / 토비스</td>
              <td>보험가입 및 처리</td>
              <td>
                사업자정보, 성명, 생년월일, 전화번호, 주민등록번호, 계좌번호
              </td>
            </tr>
            <tr>
              <td>VANPRO</td>
              <td>스테이션 고객관리</td>
              <td>성명, 생년월일, 전화번호, 결제정보</td>
            </tr>
            <tr>
              <td>㈜레티아</td>
              <td>과태료 고지서 OCR 인식</td>
              <td>과태료 고지서 첨부파일</td>
              <td rowSpan="4">
                회원탈퇴 시 혹은 위탁계약 종료 및 서비스 종료 시까지 (이용자가
                해당 서비스를 이용하는 경우에만 처리 위탁됨)
              </td>
            </tr>
            <tr>
              <td>싸인오케이</td>
              <td>전자계약서 발행</td>
              <td>계약서 PDF 파일, 성명, 이메일, 휴대전화번호</td>
            </tr>
            <tr>
              <td>세종텔레콤(주)</td>
              <td>SMS, 알림톡 등 메시지 발송 서비스 제공</td>
              <td>렌트 계약 정보, 휴대전화번호</td>
            </tr>
            <tr>
              <td>생각대로</td>
              <td>적립금 출금 결제 정보 제공</td>
              <td>CC코드, 거래구분, 금액, 차량번호, 계약기간</td>
            </tr>
            <tr>
              <td>서울보증보험</td>
              <td>신용보증보험 가입</td>
              <td>사업자정보, 성명, 주민번호, 입사일</td>
              <td>이용 목적 달성 후 관련 법령에 따른 보존기간</td>
            </tr>
            <tr>
              <td>성서인쇄</td>
              <td>명함제작</td>
              <td>성명, 휴대전화번호, 회사 메일, 회사주소</td>
              <td rowSpan="2">이용 목적 달성 후 폐기</td>
            </tr>
            <tr>
              <td>유카드소프트</td>
              <td>사원증 제작</td>
              <td>증명사진, 이름</td>
            </tr>
            <tr>
              <td>KT텔레캅</td>
              <td>임직원 출입통제관리</td>
              <td>성명, 사번, 전화번호</td>
              <td>임직원 퇴직 시까지</td>
            </tr>
          </tbody>
        </style.Table>

        <h1>제3조 개인정보의 처리위탁</h1>
        <p>
          ① 회사는 원활한 개인정보 업무처리를 위해 다음과 같이 개인정보 처리
          업무를 위탁하고 있습니다.
        </p>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>위탁업무 내용</td>
              <td>수탁업체</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>바이킹 회원가입 본인인증 대행</td>
              <td>kcb 본인인증</td>
            </tr>
            <tr>
              <td>차량 렌트료 카드 결제 대행</td>
              <td>KCP</td>
            </tr>
          </tbody>
        </style.Table>
        <p>
          ② 회사는 개인정보보호를 위해 위탁 계약 시 개인정보보호
          관리·기술·물리적 보호 조치, 수탁자 관리·감독 손해배상 책임 등에 관한
          내용을 명시한 계약서를 체결하여 수탁자를 감독하고 있습니다.
        </p>
        <br />

        <h1>제4조 개인정보 국외 이전</h1>
        <p>
          회사는 전기이륜차 배터리교환 플랫폼 제공 업체인 GOGORO 에 당사 시스템
          운영을 위탁하고 있으며 개인정보 국외 이전은 아래와 같습니다.
          <br />
          ① 개인정보를 이전 받는자 : GOGORO (개인정보처리 관련 문의처 : +886 3
          273-0900)
          <br />
          <br />
          ② 이전하는 개인정보 항목 : 플랫폼 이용 고객정보
          <br />
          <br />
          ③ 이전되는 국가, 이전 일시, 이전 방법 : 대만, 고고로 앱 가입시,
          네트워크 이용 전송
          <br />
          <br />
          ④ 이전 받는 자의 개인정보 이용 목적 및 보유, 이용 기간
          <br />
          <br />
          - 이용 목적 : 플랫폼 이용 및 차량 관리
          <br />
          - 보유 및 이용 기간 : 플랫폼 이용 종료 시, 관련 법령에 해당 시 3 년
          보관
          <br />
        </p>
        <br />

        <h1>제5조 정보주체와 법정대리인의 권리 및 행사방법</h1>
        <p>
          ① 정보주체는 언제든지 개인정보 열람·정정·삭제·처리정지 요구, 회원탈퇴,
          동의 철회 등의 권리를 행사할 수 있습니다.
          <br />
          <br />
          ② 만 14 세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이
          직접 해야 하며, 만 14 세 이상의 미성년자인 정보주체는 정보주체의
          개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을
          통하여 권리를 행사할 수도 있습니다. 다만, 당사는 현재 14 세 미만
          아동의 회원 가입은 현재 받고 있지 않습니다.
          <br />
          <br />
          ③ 권리 행사는 고객센터(1522-9008)나
          개인정보보호포털(www.privacy.go.kr)를 이용하여 요구할 수 있습니다.
          <br />
          <br />
          ④ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          <br />
        </p>
        <br />

        <h1>제6조 아동의 개인정보보호</h1>
        <p>회사는 14 세 미만 아동의 정보를 일체 수집하지 않습니다.</p>
        <br />

        <h1>제7조 개인정보의 파기</h1>
        <p>
          회사는 개인정보의 처리 목적이 달성되거나 보유기간이 경과하면 지체 없이
          개인정보를 파기하고 있으며, 파기절차 및 방법은 다음과 같습니다.
          <br />
          <br />
          ① 정보주체가 원칙적으로 개인정보 수집·이용에 대한 동의를 철회하는 경우
          개인정보를 즉시 파기합니다. 다만, 법령에 의거하여 보관해야하는 경우
          내부관리방침에 따라 별도의 데이터베이스로 이관하여 법령에서 요구하는
          최소한의 개인정보만을 보관합니다.
          <br />
          <br />
          ② 1년 동안 로그인 기록이 없는 등 회사의 서비스를 이용하지 않는
          정보주체의 개인정보를 보호하기 위하여 관련 법령에 따라 즉시 파기하거나
          다른 정보주체의 개인정보와 분리하여 별도 관리합니다.
          <br />
          <br />③ 파기 방법으로는 전자적 파일은 다시재생 할 수 없는 기술적인
          방법을 사용하여 파기하며, 종이에 출력된 개인정보는 분쇄기를 사용하거나
          소각하여 파기합니다.
        </p>
        <br />

        <h1>제8조 개인정보의 안전성 확보조치</h1>
        <p>
          회사는 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성
          확보를 위하여 다음과 같은 조치를 취하고 있습니다.
          <br />
          ① 회사는 ‘개인정보의 안전성 확보조치 기준’에 의거하여 개인정보
          내부관리지침을 수립 및 시행하고 있습니다.
          <br />
          <br />
          ② 개인정보 취급자를 최소한으로 지정하며 주기적으로 개인정보보호 교육을
          시행하고 있습니다.
          <br />
          <br />
          ③ 개인정보처리시스템에 접근 권한을 5 년간 기록하고 있으며, 업무에
          필요한 최소한 인원만 권한 부여 및 조직 이동, 퇴사 시 즉시 권한 회수를
          하고 있습니다.
          <br />
          <br />
          ④ 수집한 개인정보는 암호화하여 저장하고 있으며 송·수신할 경우 SSL
          인증서 또는 암호화 응용프로그램을 통하여 네트워크상에서 개인정보를
          안전하게 전송하고 있습니다.
          <br />
          <br />
          ⑤ 회사는 해킹이나 컴퓨터 바이러스 등에 의해 개인정보가 유출 및
          훼손되는 것을 막기 위하여 백신 등 보안프로그램을 설치하고 주기적인
          업데이트 및 점검을 진행하고 있습니다.
          <br />
          <br />
          ⑥ 개인정보를 저장하고 있는 개인정보처리시스템의 물리적 보관 장소를
          통제구역으로 지정하고 출입통제 절차를 수립·운영하고 있습니다.
          <br />
        </p>
        <br />

        <h1>제9조 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항</h1>
        <p>
          ① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를
          저장하고 수시로 불러오는 ‘쿠키(cookie)’를 운용합니다.
          <br />
          <br />
          ② 쿠키는 웹사이트를 운영하기 위해 서버(http)가 이용자의 컴퓨터
          브라우저로 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
          하드디스크에 저장되기도 합니다. 회사는(은) 다음과 같은 목적을 위해
          쿠키를 사용합니다.
          <br />
          1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹사이트들에 대한
          방문 및 이용 형태, 보안 접속 여부 등을 파악하기 위해 사용됩니다.
          <br />
          2. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷 옵션 >
          개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
          <br />
          단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을
          수 있습니다.
          <br />
        </p>
        <br />

        <h1>제10조 개인정보 보호책임자 및 열람 청구 담당부서</h1>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>담당</td>
              <td>성명</td>
              <td>부서/직책</td>
              <td>전화번호</td>
              <td>이메일</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>개인정보보호책임자</td>
              <td>이태훈</td>
              <td>관리총괄</td>
              <td>070-4050-0526</td>
              <td>thlee@gobikebank.com</td>
            </tr>
            <tr>
              <td>개인정보보호관리자</td>
              <td>이준희</td>
              <td>영업기획팀장</td>
              <td>070-4050-0591</td>
              <td>jackl@gobikebank.com</td>
            </tr>
          </tbody>
        </style.Table>
        <br />

        <h1>제11조 정보주체의 권익침해에 대한 구제방법</h1>
        <p>
          ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원
          <br />
          개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
          이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에
          문의하시기 바랍니다.
          <br />
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
          <br />
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
          <br />
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
          <br />
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
          <br />
          <br />
          ② 「개인정보 보호법」 제 35 조(개인정보의 열람), 제 36 조(개인정보의
          정정·삭제), 제 37 조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
          하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의
          침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
          있습니다.
          <br />
          ▶ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)
          <br />
        </p>
        <br />

        <h1>제12조 개인위치정보 처리에 관한 사항</h1>
        <p>
          회사는 위치정보의 보호 및 이용 등에 관한 법률에 따라 고객의
          개인위치정보를 안전하게 처리합니다.
          <br />
          <br />
          ① 회사는 이용자에게 '본인 위치 확인' 및 '현 위치 주변의 매장 정보
          제공'을 위해 위치정보를 처리하고 있으며 정보 제공 후 즉시 파기하고
          있습니다.
          <br />
          <br />
          ② 회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를 재생이
          불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일 형태는 복구
          및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물 등은 분쇄하거나
          소각하는 방식으로 파기합니다.
          <br />
          <br />
          ③ 회사는 고객이 제공한 개인위치정보를 동의 없이 제 3 자에게 제공하지
          않으며, 고객이 지정하는 제 3 자에게 제공할 경우 고객에게 매회 제공받는
          자, 제공일시 및 제공목적을 즉시 통보합니다.
          <br />
          <br />
          ④ 회사는 아래에 해당하는 고객(이하 "8 세 이하의 아동 등"이라 한다)의
          보호의무자가 8 세 이하 아동 등의 생명 또는 신체의 보호를 위하여 8 세
          이하의 아동 등의 개인위치정보의 수집·이용 또는 제공에 동의하는
          경우에는 본인의 동의가 있는 것으로 봅니다.
          <br />
          - 8 세 이하의 아동
          <br />
          - 피성년후견인
          <br />
          - 장애인복지법 제 2 조제 2 항제 2 호에 따른 정신적 장애를 가진
          사람으로서 장애인고용촉진 및 직업재활법 제 2 조제 2 호에 따른
          중증장애인에 해당하는 사람
          <br />
          (장애인복지법 제 32 조에 따라 장애인 등록을 한 사람만 해당한다)
          <br />
          위 권리를 행사하고자 하는 보호의무자는 서면동의서에 8 세 이하 아동
          등의 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          <br />
          <br />
          ⑤ 회사의 개인위치정보 보호업무 및 관련 문의는 개인정보보호 부서에서
          담당하고 있으며 개인정보 보호책임자가 위치정보 관리책임자를 겸직하고
          있습니다.
          <br />
        </p>
        <br />

        <h1>제13조 영상정보처리기기 운영·관리에 관한 사항</h1>
        <p>
          ① 영상정보처리기기의 설치 근거 및 설치 목적
          <br />
          {COMPANY_NAME_KO} 주식회사는 개인정보보호법 제25 조 제1항에 따라
          다음과 같은 목적으로 영상정보처리기기를 설치·운영합니다.
          <br />
          1. 시설안전 및 화재 예방
          <br />
          2. 임직원 및 내방 고객의 안전을 위한 범죄 예방
          <br />
          <br />
          ② 설치 대수 , 설치 위치 및 촬영범위
          <br />
        </p>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td colSpan="2">구분</td>
              <td>설치대수</td>
              <td>설치위치</td>
              <td>촬영범위</td>
              <td>촬영시간</td>
              <td>보관기간</td>
              <td>보관장소</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2">본사</td>
              <td>5층</td>
              <td>4대</td>
              <td>복도 및 탕비실</td>
              <td>복도 및 탕비실內</td>
              <td>24시간</td>
              <td>촬영일로부터 15일 이내</td>
              <td>본사 5층 531호 사무실</td>
            </tr>
            <tr>
              <td>6층</td>
              <td>1</td>
              <td>복도</td>
              <td>복도</td>
              <td>24시간</td>
              <td>촬영일로부터 15일 이내</td>
              <td>본사 5층 531호 사무실</td>
            </tr>
          </tbody>
        </style.Table>

        <p>
          ③ 관리책임자 및 접근권한자
          <br />
          이용자의 영상정보를 보호하고 개인영상정보와 관련한 불만을 처리하기
          위하여 아래와 같이 개인영상정보 보호책임자를 두고 있습니다.
        </p>

        <style.Table cellSpacing={"0"}>
          <thead>
            <tr>
              <td>수탁업체</td>
              <td>담당자</td>
              <td>연락처</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>kt 텔레캅</td>
              <td>우상길</td>
              <td>053-654-0250</td>
            </tr>
          </tbody>
        </style.Table>

        <p>
          ⑤ 개인영상정보의 확인 방법 및 장소
          <br />
          1. 확인방법 : 개인영상정보 관리책임자에게 미리 연락하고 본사를
          방문하시면 확인 가능합니다.
          <br />
          2. 확인장소 : 본사 사무실
          <br />
          <br />
          ⑥ 정보주체의 영상정보 열람 등 요구에 대한조치
          <br />
          이용자는 개인영상정보에 관하여 열람 또는 존재확인·삭제를 원하는 경우
          언제든지 영상정보처리기기 운영자에게 요구하실 수 있습니다. 단,
          이용자가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체,
          재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다.
          <br />
          <br />
          ⑦ 개인영상정보의 안정성 확보조치
          <br />
          회사는 처리하는 영상정보는 암호화 조치 등을 통하여 안전하게 관리되고
          있습니다. 또한 회사는 개인영상정보보호를 위한 관리적 대책으로서
          개인정보에 대한 접근 권한을 차등부여하고 있고, 개인영상정보의 위·변조
          방지를 위하여 개인영상정보의 생성 일시, 열람 시 열람 목적·열람자·열람
          일시 등을 기록하여 관리하고 있습니다. 이 외에도 개인영상정보의 안전한
          물리적 보관을 위하여 잠금장치를 설치하고 있습니다.
          <br />
          <br />
        </p>
        <br />
        <br />
        <p>
          개인정보 처리방침 버전번호: 1.0
          <br />
          개인정보처리방침 시행일자 : 2022 년 9 월 26 일
        </p>
      </style.Wrap>
    </>
  );
};

export default Privacy;
