import { Cookies } from 'react-cookie';

const cookies = new Cookies();

/*
 * @constant
 */
export const getCookie = (key) => {
  return cookies.get(key);
};

/*
 * @constant
 */
export const setCookie = (key, value, option) => {
  return cookies.set(key, value, option);
};

/*
 * @constant
 */
export function removeCookie(name) {
  cookies.remove(name);
}
