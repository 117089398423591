import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as style from './styles';
import * as component from '../../components';
import * as utils from '../../utils';

// 스크롤이벤트
import AOS from 'aos';
import 'aos/dist/aos.css';
// 슬라이드
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { COMPANY_NAME_KO } from '../../utils/utilsConstant';

const Home = () => {
  // 화면 이동을 위한 변수
  const navigate = useNavigate();
  // 모바일 유무 확인
  const { isMobile } = useContext(utils.mobileContext);

  const [isPopupShow, setIsPopupShow] = useState(false); //배너 show/hide

  useEffect(() => {
    // 스크롤 이벤트를 위한 설정
    AOS.init({ duration: 1800 });
    // 스크롤 최상단
    window.scrollTo(0, 0);

    //팝업 하루동안 보지않음 체크 유무 확인
    let bannerShow = utils.getCookie('popup_show');
    if (bannerShow !== false) {
      setIsPopupShow(true);
    }
  }, []);

  const todayStopBtn = (idx) => {
    let date = new Date();
    let expires = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

    if (idx === 1) {
      utils.setCookie('popup_show', false, { expires: expires });
      setIsPopupShow(false);
    }
  };

  return (
    <>
      {/* 메타태그 */}
      <component.MetaTag />

      {/* 팝업 */}
      {/* {isPopupShow && (
        <style.PopupWrap isMobile={isMobile}>
          <img src="/images/main_popup_2.jpg?ver=1" alt="바이크뱅크프로모션" />
          <div className="button_wrap">
            <button onClick={() => todayStopBtn(1)}>오늘 그만 보기</button>
            <button onClick={() => navigate('/notice/2')}>더알아보기</button>
          </div>
        </style.PopupWrap>
      )} */}

      {/* 메인 스와이퍼 */}
      <Swiper loop={true} slidesPerView={1}>
        <SwiperSlide>
          <style.Visual isMobile={isMobile}>
            <style.PageWidth gap={isMobile ? '30px' : '75px'} isMain flexEnd>
              <style.Title isMobile={isMobile}>
                {isMobile ? 'No. 1' : 'No.1'} <br />
                비즈니스 이륜차 솔루션
                <br />
                <span>{COMPANY_NAME_KO}</span>
              </style.Title>
              <style.Button onClick={() => navigate(`/rent`)}>렌트상품 알아보기</style.Button>
            </style.PageWidth>
          </style.Visual>
        </SwiperSlide>
      </Swiper>

      {/* 공지사항 */}
      <style.Section padding={isMobile ? '50px 30px' : '84px'}>
        <style.PageWidth isMain>
          <style.Notice isMobile={isMobile}>
            {utils.NoticeList.map((item) => {
              const { type, desc, id } = item;
              return (
                <li data-aos="fade-up" data-aos-duration="1800" data-aos-delay={id * 500} onClick={() => navigate('/customer/1')}>
                  <p>[{type}]</p>
                  <p>{desc}</p>
                </li>
              );
            })}
          </style.Notice>
        </style.PageWidth>
      </style.Section>
      <style.GrayLine />

      {/* SOLUTION */}
      <style.Section padding={isMobile ? '80px 0 0 0' : '84px 0'}>
        <style.PageWidth isMain>
          <component.HomeSectionTitle title="solution" subtitle={isMobile ? '기술과 인프라로' : '기술과 인프라를 바탕으로 제공하는'} bold={isMobile ? '이륜차 솔루션을 제공합니다.' : '이륜차 솔루션을 이용해보세요'} />
          {!isMobile && (
            <style.SolutionUl data-aos="fade-up" data-aos-duration="1800">
              {utils.SolutionList.map((item) => {
                const { img, alt, title, mobileimg } = item;
                return (
                  <li>
                    <img src={isMobile ? mobileimg : img} alt={alt} />
                    <p>{title}</p>
                  </li>
                );
              })}
            </style.SolutionUl>
          )}
        </style.PageWidth>
      </style.Section>
      <>
        {isMobile && (
          <>
            <style.SolutionUl data-aos="fade-up" data-aos-duration="1800" isMobile>
              {utils.SolutionList.map((item) => {
                const { img, alt, title, mobileimg } = item;
                return (
                  <li>
                    <img src={isMobile ? mobileimg : img} alt={alt} />
                    <p>{title}</p>
                  </li>
                );
              })}
            </style.SolutionUl>
            <style.GrayLine />
          </>
        )}
      </>

      {/* RENT */}
      <style.Section isMain padding={isMobile ? '54px 0 100px 0' : '148px 0 100px 0'}>
        <style.PageWidth isMain>
          <component.HomeSectionTitle
            title="rent" //
            subtitle={isMobile ? '국내 이륜차 렌트 점유율 1위' : '국내 이륜차 렌트 점유율 1위 업체로서'}
            bold={isMobile ? '최고의 상품을 공급합니다.' : '최고 수준의 배달용 렌트 상품을 공급합니다'}
            desc1={isMobile ? '출고부터 관리, 사고 보장까지 한 번에.' : '전국 8,000여대의 렌트 이륜차를 운용하며 출고부터 관리, 사고 보장까지 한 번에'}
            desc2={!isMobile && `어려운 오토바이 관리를 ${COMPANY_NAME_KO}에서 편하게 이용하세요.`}
          />
        </style.PageWidth>
        <component.RentCountUp />
      </style.Section>

      {/* PARTNERS */}
      <style.Section isMain padding={isMobile ? '54px 0 100px 0' : '130px 0 86px 0'} background="#f8f8f8">
        <style.PageWidth isMain>
          <component.HomeSectionTitle
            title="partners" //
            subtitle={isMobile ? '비즈니스 파트너들에게' : `${COMPANY_NAME_KO}는 다양한 비즈니스 파트너들에게`}
            bold={isMobile ? '이륜차 솔루션을 제공합니다.' : '이륜차 솔루션을 제공합니다'}
            desc1={!isMobile && '* 2024년 3월 기준'}
          />
          {isMobile && (
            <style.Imgbox isMobile isMain>
              <img src="/images/main/mobile_img_partners.png" alt="파트너 이미지" data-aos="fade-up" data-aos-duration="1800" style={{ marginTop: '50px' }} />
              <p>* 2024년 3월 기준</p>
            </style.Imgbox>
          )}
        </style.PageWidth>
        {!isMobile && (
          <style.Imgbox>
            <img src="/images/main/img_partners.png" alt="파트너 이미지" data-aos="fade-up" data-aos-duration="1800" />
          </style.Imgbox>
        )}
      </style.Section>

      {/* CHECK POINT */}
      <style.Section padding="141.5px 0" backgroundImg="url('/images/check_bg.jpg')" style={{ maxHeight: '800px' }}>
        <style.PageWidth isMain>
          <component.HomeSectionTitle width="0%" title="check point" subtitle="배달용 렌트의 기준" bold="세 가지를 꼭 체크하세요" subcolor="#fff" sbblod color={isMobile ? '#ffc400' : '#fff'} noletter />
          <style.CheckPointList>
            {utils.checkpoint_list.map((item) => {
              const { id, number, title, desc } = item;
              return (
                <li key={id} data-aos="fade-up" data-aos-duration="1800" data-aos-delay={item.id * 800}>
                  <p>{number}</p>
                  <p>{title}</p>
                  <p>{desc}</p>
                </li>
              );
            })}
          </style.CheckPointList>
        </style.PageWidth>
      </style.Section>

      {/*  CHECK POINT 01 */}
      <style.Section padding={isMobile ? '164px 0 75px 0' : '165px 0 237px 0'}>
        <style.PageWidth isMain>
          <component.HomeCheckList title1="배달용 이륜차 선택할 때" title2="핵심은 보장 범위" number="01" desc1={!isMobile && '긴 시간을 주행하는 라이더에게는 사고 발생시 보장 범위가 중요합니다.'} desc2={!isMobile && '보장 범위를 비교하고 상품을 선택하세요.'} noletter={!isMobile} />
          <style.InsuranceList>
            <ul>
              {utils.insurance_coverage.map((item) => {
                const { id, title, desc } = item;
                return (
                  <li key={id} data-aos="fade-up" data-aos-duration="1800" data-aos-delay={id * 800}>
                    <p>{title}</p>
                    <span>{desc}</span>
                  </li>
                );
              })}
            </ul>
            {!isMobile && (
              <style.HomeButton maxWidth="230px" onClick={() => navigate(`/rent/3`)}>
                보험보장 상세내용
              </style.HomeButton>
            )}
          </style.InsuranceList>

          {/* rsc 보장사례 */}
          <style.InsuranceSubtitle>RCS 보장사례</style.InsuranceSubtitle>
          <style.RCSList data-aos="fade-up">
            {utils.insurance_example.map((item) => {
              const { id, title, boldtitle, subtitle, url, desc } = item;
              return (
                <li key={id}>
                  <p>{title}</p>
                  <p className="desc">
                    <span>{boldtitle}</span>
                    {subtitle}
                  </p>
                  <p onClick={() => navigate(url)}>{desc}</p>
                </li>
              );
            })}
          </style.RCSList>
        </style.PageWidth>
      </style.Section>

      {/*  CHECK POINT 02 */}
      <style.Section padding="118.5px 0 124px 0" background="#f8f8f8">
        <style.PageWidth isMain isMobile={isMobile}>
          <component.HomeCheckList title1="전국 430개 이상의" title2="서비스망으로 확실하게" number="02" desc1={isMobile ? '이륜차 소모품 교환과 사고 수리.' : '배달 권역별로 서비스망을 확보하여 이륜차 운용에 필요한'} desc2={!isMobile && '소모품 무료 교환과 사고 발생시 수리를 책임집니다.'} noletter />
        </style.PageWidth>
        <style.Area isMain>
          <img src={isMobile ? '/images/main/mobile_img_service_area.png' : '/images/main/img_service_area.png'} alt="서비스망 이미지" data-aos="fade-up" onClick={() => navigate('/customer/2')} />
          {!isMobile && (
            <style.AreaTextBox>
              <p>서비스망 알아보기 &#62;</p>
              <p>* 2023년 3월 기준</p>
            </style.AreaTextBox>
          )}
        </style.Area>
        {isMobile && (
          <style.AreaTextBox>
            <p>서비스망 알아보기 &#62;</p>
          </style.AreaTextBox>
        )}
      </style.Section>

      {/*  CHECK POINT 03 */}
      <style.Section padding="168px 0 251.5px 0">
        <style.PageWidth isMain isMobile={isMobile}>
          <component.HomeCheckList
            title1="배달용 오토바이 필요할 때" //
            title2="비용 부담을 줄이도록"
            number="03"
            desc1={!isMobile && '오토바이 구입비에 보험 가입비까지'}
            desc2={!isMobile && `갑작스러운 비용 증가가 부담된다면 ${COMPANY_NAME_KO} 렌트 상품`}
            margin="0 0 52px 0"
            noletter
          />
        </style.PageWidth>
        <style.Imgbox isMain>
          <img src={isMobile ? '/images/main/mobile_img_checkpoint03.png' : '/images/main/img_checkpoint03.png'} alt="렌트비 이미지" data-aos="fade-up" />
        </style.Imgbox>
        <style.PageWidth isMain>{!isMobile && <style.HomePriceDesc>&#60;23세 남자/ 125cc/ 소모품 교체기준, 차량에 따라 달라질 수 있습니다.&#62;</style.HomePriceDesc>}</style.PageWidth>
      </style.Section>
    </>
  );
};
export default Home;
