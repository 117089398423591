import styled, { css } from 'styled-components';

export const Visual = styled.main`
  width: 100%;
  height: 970px;
  background: ${(props) => (props.isMobile ? "url('/images/mobile_index_visual.png')" : "url('/images/main_visual_bg_01.jpg')")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    max-height: 570px;
    background-size: cover;
  }
`;

// 페이지 중앙 정렬을 위한 div
// gap : 컨텐츠들의 gap 이 필요할시 따로 지정
// imgMargin : 이미지의 마진이 필요할시 따로 지정
export const PageWidth = styled.div`
  max-width: 1194px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '0'};
  img {
    margin: ${(props) => props.imgMargin || ''};
  }
  justify-content: ${(props) => props.isCenter && 'center'};
  ${(props) =>
    props.isMain &&
    css`
      max-width: ${({ theme }) => theme.contentWidth};
      padding: 0;
      @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
        padding: 0;
      }
    `}

  ${(props) =>
    props.flexEnd &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
    
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: ${(props) => props.padding || ' 0 0 0 30px'};
    ${({ isMain }) =>
      isMain &&
      css`
        padding: 0;
      `}
    ${(props) =>
      props.bikeDetail &&
      css`
        padding: 0 30px;
      `}
  }
  .infraImg {
    margin: 0 auto;
    width: 100%;
    max-width: 933px;
    @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
      padding-right: 15px;
      margin-left: -15px;
    }
  }
  .rentImg {
    box-shadow: 5px 0 30px 0 rgba(125, 125, 125, 0.16);
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 38px 57px;
    border-radius: 15px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .rscdesc {
    text-align: right;
    color: #999;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin: 16px 0 10px 0;
  }
  .option_desc {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.6px;
    color: #5d5d5d;
  }
  .mobile_rscdesc {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.6px;
    font-weight: 700;
    white-space: pre-wrap;
  }
`;

export const Title = styled.p`
  font-size: 60px;
  line-height: 75px;
  letter-spacing: 0px;
  color: white;
  span {
    font-weight: 600;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.8px;
    padding: 0 30px;
  }
`;

export const Button = styled.button`
  border-radius: 32.5px;
  background-color: ${(props) => props.background || '#ffce30'};
  padding: 19px 40px;
  font-weight: 400;
  font-size: 19px;
  max-width: ${(props) => props.maxWidth || '227.5px'};
  margin-bottom: ${(props) => props.mb || '151px'};
  margin-top: ${(props) => props.mt || '0'};
  color: ${(props) => props.color || '#000'};
  ${(props) =>
    props.isCenter &&
    css`
      margin: 0 auto;
      padding: 19px 78.5px;
    `}
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    margin-bottom: 60px;
    padding: ${(props) => props.padding || '11px 18px'};
    font-size: 14px;
    width: 146px;
    height: 47px;
    border-radius: 10px;
    letter-spacing: -0.4px;
    margin-left: 30px;
  }
`;

export const HomeButton = styled.button`
  width: 240px;
  height: 65px;
  border-radius: 33px;
  background-color: #ffce30;
  text-align: center;
  line-height: 65px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
`;

export const Section = styled.section`
  padding: ${(props) => props.padding || ''};
  background-color: ${(props) => props.background || 'white'};
  border-bottom: ${(props) => props.borderBt && '2px solid #e1e1e1'};
  background-image: ${(props) => props.backgroundImg || ''};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    .imgWidth100 {
      max-width: 100%;
      padding: ${(props) => props.imgPadding || ''};
    }
    .partner {
      width: 100%;
      margin-top: -35px;
      padding: 0 30px;
    }
  }
`;

export const Notice = styled.ul`
  display: flex;
  gap: 21px;
  justify-content: center;
  align-items: center;
  li {
    cursor: pointer;
    width: 33.3333%;
    height: 346px;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 10px 0 30px 0 rgba(187, 187, 187, 0.16);
    background-image: linear-gradient(to bottom, #ffe182, #fff4cf);
    display: flex;
    flex-direction: column;
    gap: 62px;
    p {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.85px;
      color: #272727;
      white-space: pre-wrap;
      cursor: pointer;
      transition: all 0.3s;
      &:last-child {
        line-height: 46px;
      }
    }
    &:hover {
      p {
        font-weight: 600;
      }
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      gap: 10px;
      li {
        width: 100%;
        height: 103px;
        padding: 27px 24px;
        gap: 0;
        justify-content: center;
        p {
          white-space: nowrap;
          font-size: 18px;
          line-height: 28px;
          &:last-child {
            line-height: 28px;
          }
        }
      }
    `}
`;

export const GrayLine = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: #e1e1e1;
`;

export const SolutionUl = styled.ul`
  margin-top: -2px;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: visible;
  gap: 21px;
  li {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    p {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      color: ${(props) => props.theme.black};
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100%;
      overflow: scroll;
      gap: 10px;
      padding-right: 15px;
      white-space: nowrap;
      flex-wrap: nowrap;
      padding-right: 30px;
      margin-bottom: 100px;
      li {
        width: 335px;
        height: 100%;
        gap: 0;
        justify-content: flex-start;
        img {
          height: 280px;
          width: 335px;
          object-fit: cover;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.64px;
          color: ${(props) => props.theme.gray02};
          margin-left: 30px;
          margin-top: 10px;
        }
      }
    `}
`;

export const CheckPointList = styled.ul`
  display: flex;
  gap: 24px;
  color: white;
  margin-top: 190px;
  li {
    width: 33.33333%;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
    p {
      font-weight: 700;
      &:first-child {
        font-family: 'SF-Pro-Display', sans-serif;
        font-weight: bold;
        letter-spacing: 0px;
        font-size: 24px;
        line-height: 24px;
        &:after {
          content: '';
          width: 100%;
          display: block;
          height: 2px;
          background-color: white;
          margin: 9px 0 10px 0;
        }
      }
      &:last-child {
        font-size: 18px;
        letter-spacing: -0.45px;
        margin-top: 5px;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    flex-direction: column;
    margin: 30px 30px 0 30px;
    gap: 37px;
    li {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      p {
        letter-spacing: 0;
        &:first-child {
          line-height: 20px;
          font-size: 20px;
          &:after {
            margin: 9px 0;
          }
        }
        &:last-child {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.64px;
          font-weight: 400;
          margin-top: 0;
        }
      }
    }
  }
`;

export const InsuranceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 50px 0 62px 0;
  ul {
    display: flex;
    gap: 28px;
    width: 100%;
    li {
      width: 33.33333%;
      /* max-width: 344px; */
      display: flex;
      flex-direction: column;
      box-shadow: 5px 0 30px 0 rgba(125, 125, 125, 0.16);
      align-items: center;
      padding: 50px 0;
      border-radius: 15px;
      height: 200px;
      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
      }
      span {
        color: ${(props) => props.theme.primary};
        font-size: 38px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0px;
        margin-top: 9px;
      }
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #999;
    margin-top: 34px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: 0 30px;
    margin: 0;
    margin-top: -10px;
    &:after {
      display: none;
    }
    ul {
      flex-direction: column;
      gap: 0;
      li {
        min-width: 100%;
        box-shadow: none;
        align-items: flex-start;
        gap: 5px;
        border-bottom: 1px solid #e1e1e1;
        padding: 22px 0;
        border-radius: 0;
        height: 130px;
        p {
          color: #ffc400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.7px;
          font-weight: 500;
        }
        span {
          color: #000;
          font-size: 38px;
          letter-spacing: -1px;
          line-height: 55px;
          margin-top: 0;
        }
      }
    }
  }
`;

export const InsuranceSubtitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.6px;
  margin-bottom: 32.5px;
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => props.theme.gray02};
    letter-spacing: -0.64px;
    font-weight: 400;
    margin: 32px 0 0 31px;
  }
`;

export const RCSList = styled.ul`
  display: flex;
  gap: 50px;
  ::-webkit-scrollbar {
    display: none;
  }
  li {
    box-shadow: 5px 0 30px 0 rgba(125, 125, 125, 0.16);
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-height: 374px;
    p {
      font-size: 30px;
      font-weight: 500;
      line-height: 49px;
      text-align: center;
      color: #000;
      width: 100%;
      white-space: pre-wrap;
      font-weight: 400;
      letter-spacing: -0.8px;
      /* title */
      &:first-child {
        font-size: 26px;
        background-image: url('/images/background_gray.jpg');
        color: white;
        width: 100%;
        margin-bottom: 34px;
        height: 108px;
        line-height: 110px;
        letter-spacing: -0.7px;
        font-weight: 500;
      }
      &:last-child {
        font-size: 20px;
        color: ${(props) => props.theme.gray02};
        margin: 29.5px 57.5px 0 57.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        color: #000;
        padding: 19.5px 0 40.6px 0;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: 0;
        &:before {
          content: '';
          height: 1px;
          width: 400px;
          min-width: 54.5px;
          background-color: ${(props) => props.theme.gray02};
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &:after {
          content: '';
          background: url('/images/after_guar.png') no-repeat center;
          background-size: contain;
          width: 12px;
          height: 29px;
          display: block;
          margin-left: 20px;
        }
      }
      span {
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -1.1px;
        font-weight: 500;
      }
    }
    &:first-child {
      p {
        &:first-child {
          background-image: url('/images/background_blue.jpg');
        }
        &:nth-child(2) {
          color: #0c76d2;
        }
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.tablet}) {
    gap: 30px;
    li {
      p {
        &:last-child {
          font-size: 22px;
          &:after {
            margin-left: 10px;
            width: 8.5px;
            height: 14px;
            margin-top: 1px;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    width: 100%;
    flex-wrap: nowrap;
    overflow: scroll;
    gap: 9px;
    padding: 10px 30px 100px 40px;
    justify-content: space-between;
    li {
      min-width: 306px;
      p {
        font-size: 22px;
        line-height: 44px;
        letter-spacing: -0.6px;
        &.desc {
          margin-top: 15px;
        }
        /* title */
        &:first-child {
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -0.5px;
          padding: 0;
          height: 54px;
          margin-bottom: 6px;
          padding: 13px 0;
          font-weight: 700;
        }
        &:last-child {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0px;
          padding: 15px 33px;
          margin-top: 14.5px;
          font-weight: 400;
          &:before {
            max-width: calc(100% - 66px);
          }
          &:after {
            background: url('/images/arrow_rcs.png') no-repeat center;
            background-size: contain;
            width: 6.6px;
            height: 10.9px;
            margin-left: 10px;
          }
        }
        span {
          font-size: 35px;
          line-height: 46px;
          letter-spacing: -0.9px;
          font-weight: 500;
        }
      }
    }
  }
`;

export const Area = styled.div`
  max-width: 1284px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    cursor: pointer;
  }
  .infra {
    @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
      margin-top: -20px;
    }
  }
  .rscdesc {
    text-align: left;
    width: 100%;
    color: #272727;
    font-weight: 700;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: -0.64px;
    padding: 30px 0 30px 30px;
    span {
      color: #d60000;
    }
  }
  ${(props) =>
    props.isMain &&
    css`
      max-width: 1170px;
    `}
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding-top: 30px;
  }
`;

export const AreaTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 70px;
  width: calc(100% - 90px - 40px);
  p {
    color: #999;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;
    &:last-child {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    position: relative;
    display: block;
    top: 5px;
    p {
      color: #5d5d5d;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.56px;
      padding-left: 36px;
    }
  }
`;

export const HomePriceDesc = styled.p`
  text-align: right;
  margin-top: 8.5px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #5d5d5d;
`;

export const Imgbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 1080px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    img {
      max-width: 100%;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      background-color: #fff;
      max-width: 100%;
      border-radius: 10px;
      /* margin-right: 30px; */
      padding: 60px 32px;
      position: relative;
      img {
        max-width: 100%;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #5d5d5d;
        position: absolute;
        left: 0;
        bottom: -28px;
      }
    `}

  ${({ isMain }) =>
    isMain &&
    css`
      img {
        margin: 0 auto;
      }

      @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
        margin: 0 30px;
        img {
          max-width: 100%;
        }
      }
    `}
`;

export const PopupWrap = styled.div`
  width: 95%;
  max-width: 500px;
  position: fixed;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #000000; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
    `}

  .button_wrap {
    display: flex;
    width: 100%;
    max-width: 500px;
    gap: 1px;
    background-color: #b3b3b3;
    button {
      width: 50%;
      height: 40px;
      background-color: #fff;
    }
  }
`;
