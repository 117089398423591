import React, { useContext } from 'react';
import styled from 'styled-components';
// countup
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import * as utils from '../../utils';

const RentCountUp = () => {
  const { isMobile } = useContext(utils.mobileContext);

  const formatter = (value) => {
    return value.toLocaleString();
  };
  return (
    <Wrap>
      <ImgWrap>
        <img src={isMobile ? '/images/main/mobile_img_rent.png' : '/images/main/img_rent.png'} alt="렌트 이미지" data-aos="fade-up" />
      </ImgWrap>
      <CountUpList data-aos="fade-up" data-aos-duration="1800">
        <li>
          <p>{isMobile ? '운영 도시 수' : '운영도시'}</p>
          {/* countUp */}
          <CountUp start={0} end={70} startOnMount={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span>{isMobile && ' 개'} +</span>
        </li>
        <li>
          <p>{isMobile ? '사용점 수' : '사용점'}</p>
          <CountUp start={0} end={2300} startOnMount={true} separator="," formatter={formatter}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span className="plus">{isMobile && ' 점'} +</span>
        </li>
        <li>
          <p>{isMobile ? '렌트 수' : '렌트수'}</p>
          <CountUp start={0} end={8000} startOnMount={true} separator="," formatter={formatter}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          <span className="plus">{isMobile && ' 대'} +</span>
        </li>
      </CountUpList>
    </Wrap>
  );
};

export default RentCountUp;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 20px auto;
  width: 100%;
  max-width: ${({ theme }) => theme.contentWidth};
  img {
    max-width: 100%;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    margin: 30px 0 20px 0;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const CountUpList = styled.ul`
  display: flex;
  max-width: ${({ theme }) => theme.contentWidth};
  width: 100%;
  li {
    width: 33.33333%;
    p {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.6px;
      font-weight: 500;
      color: ${(props) => props.theme.primary};
      margin-bottom: 40px;
    }
    span {
      font-size: 70px;
      line-height: 70px;
      letter-spacing: -1.8px;
      font-family: 'SF-Pro-Display', sans-serif;
      font-weight: bold;
      font-style: normal;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    flex-direction: column;
    padding: 0 30px;
    li {
      width: 100%;
      border-bottom: 1px solid #e1e1e1;
      padding: 20px 0 23px 0;
      &:last-child {
        border: none;
      }
      p {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.72px;
        margin: 0px 0 10px 0;
        color: #ffc400;
        font-weight: 400;
      }
      span {
        font-size: 42px;
        line-height: 42px;
        letter-spacing: 0px;
      }
    }
  }
`;
