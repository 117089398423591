import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as homestyle from '../../home/styles';
import * as style from './styles';
import * as utils from '../../../utils';
import * as component from '../../../components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const SaleBike = () => {
  // url 파라미터값을 가져온다.
  const { id } = useParams();
  // 모바일 유무 확인
  const { isMobile } = useContext(utils.mobileContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const replaceUnderscore = (text) => {
    return text?.replace(/_/g, ' ');
  };

  const [data, setData] = useState();

  useEffect(() => {
    setData(utils?.detailIntroList?.filter((item) => item.title === id)[0]);
  }, [id]);

  return (
    <>
      {/* 메타태그 */}
      <component.MetaTag
        title={replaceUnderscore(id)} //
        keywords={replaceUnderscore(id)}
        description={replaceUnderscore(id)}
        subject={replaceUnderscore(id)}
        url={`http://www.bikebank.kr/sale/${id}`}
      />

      <style.Top>
        <div className="imgbox">{isMobile ? <img src={`/images/sale/detail/mobile_img_main_${id}.png`} alt={id} /> : <img src={`/images/sale/detail/img_main_${id}.jpg`} alt={id} />}</div>
        <style.TextBox>
          <p className="title">{replaceUnderscore(data?.title)}</p>
          <p className="desc">{data?.desc}</p>
          <hr />
          <p className="price">{data?.price}</p>
        </style.TextBox>
      </style.Top>
      <homestyle.Section padding={isMobile ? '0' : '80px 0 0 0'}>
        <homestyle.PageWidth bikeDetail={isMobile}>
          <style.DesignTitleBox>
            <p className="title">{data?.title?.includes('S3') || data?.title?.includes('S5') ? `전기자전거 ${data?.title}` : replaceUnderscore(data?.title)}</p>
            <p className="desc">{data?.desc}</p>
          </style.DesignTitleBox>
          <style.DesignBox>
            <li>
              <img src={`/images/sale/detail/img_design_${id}_01.png`} alt={id} />
              {isMobile ? <p>{data?.title?.includes('S3') ? data?.mobile_design_desc01 : data?.design_desc01}</p> : <p>{data?.design_desc01}</p>}
            </li>
            <li>
              <img src={`/images/sale/detail/img_design_${id}_02.png`} alt={id} />
              {isMobile ? <p>{data?.title?.includes('S3') ? data?.mobile_design_desc02 : data?.design_desc02}</p> : <p>{data?.design_desc02}</p>}
            </li>
            <li>
              <img src={`/images/sale/detail/img_design_${id}_03.png`} alt={id} />
              {isMobile ? <p>{data?.title?.includes('S3') ? data?.mobile_design_desc03 : data?.design_desc03}</p> : <p>{data?.design_desc03}</p>}
            </li>
          </style.DesignBox>
          <style.TitleBox>
            <p>
              {data?.detail_title01}
              <span>{data?.detail_title02}</span>
            </p>
            {!isMobile && (
              <div>
                <p>Color</p>
                <ul>
                  {data?.color?.map((i) => (
                    <style.ColorItem color={i}></style.ColorItem>
                  ))}
                </ul>
              </div>
            )}
          </style.TitleBox>
          <style.OptionBox>
            <ul>
              {data?.option_list01?.map((i) => {
                const { title, desc } = i;
                return (
                  <li>
                    <p>
                      <span>{title}</span>
                      {desc}
                    </p>
                  </li>
                );
              })}
            </ul>
            <ul>
              {data?.option_list02?.map((i) => {
                const { title, desc } = i;
                return (
                  <li>
                    <p>
                      <span>{title}</span>
                      {desc}
                    </p>
                  </li>
                );
              })}
            </ul>
          </style.OptionBox>
          {isMobile && (
            <style.ColorBox>
              <p>Color</p>
              <style.ColorList>
                {data?.color?.map((i) => (
                  <style.ColorItem color={i}></style.ColorItem>
                ))}
              </style.ColorList>
            </style.ColorBox>
          )}
          <style.SwiperWrap>
            <Swiper loop={true} slidesPerView={1} modules={[Navigation, Autoplay]} autoplay={true} navigation={true} scrollbar={{ draggable: true }}>
              {data?.swiper?.map((i) => (
                <style.SwiperItem key={i}>
                  <img src={i} alt={id} />
                </style.SwiperItem>
              ))}
            </Swiper>
          </style.SwiperWrap>
          {data?.gallery && <style.GalleryTitle>Gallery</style.GalleryTitle>}
        </homestyle.PageWidth>
        <style.GalleryWrap
          loop={true}
          scrollbar={{ draggable: true }}
          slidesPerView={2.4}
          slidesOffsetBefore={130}
          spaceBetween={60}
          breakpoints={{
            1000: {
              slidesOffsetBefore: 100,
              spaceBetween: 40,
              slidesPerView: 2.4,
            },
            900: {
              slidesOffsetBefore: 80,
              spaceBetween: 30,
              slidesPerView: 2.5,
            },
            300: {
              slidesOffsetBefore: 50,
              spaceBetween: 20,
              slidesPerView: 1.5,
            },
          }}
        >
          {data?.gallery?.map((i) => (
            <SwiperSlide key={i}>
              <img src={i} alt={id} />
            </SwiperSlide>
          ))}
        </style.GalleryWrap>
        <style.CustomerWrap>
          <p>
            고객센터로 문의주시면 <br />
            가까운 판매점을 안내해드리겠습니다
          </p>
          <button>
            <a href="tel:1522-9088">1522-9008</a>
          </button>
        </style.CustomerWrap>
      </homestyle.Section>
    </>
  );
};

export default SaleBike;
