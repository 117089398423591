import React from 'react';
import styled, { css } from 'styled-components';
import { Desc } from '../home/HomeSectionTitle';

const TitleList = ({ number, subtitle, bold, bold2, desc1, desc2, desc3, nonSubtitle, noneNum, noDesc, isMobile, descright, noneletter, noneboldletter, nopadding, small, margin }) => {
  return (
    // noneSubtitle : subtitle이 없으면 지정해준다.
    // isMobile : mobile 유무에 따라 달라지기때문에 전달해준다.
    <Wrap nonSubtitle={nonSubtitle} isMobile={isMobile} noneboldletter={noneboldletter} margin={margin}>
      {/* 숫자 */}
      <Number noneNum={noneNum}>{number}</Number>
      {/* fontweight 가 normal 인 subtitle */}
      <span>
        {subtitle}
        {/* bold체인 부분 */}
        <p className="bold">{bold}</p>
        <p>{bold2}</p>
      </span>
      {/* noDesc : description 이 없는경우 지정해준다. */}
      <DescWrap noDesc={noDesc} nopadding={nopadding}>
        {/* descright : 첫번째 description이 오른쪽에 붙어있는 경우에만 지정해준다. */}
        <Desc small={small} noneletter={noneletter} descright={descright}>
          {desc1}
        </Desc>
        <Desc small={small} noneletter={noneletter}>
          {desc2}
        </Desc>
        <Desc small={small} noneletter={noneletter}>
          {desc3}
        </Desc>
      </DescWrap>
    </Wrap>
  );
};

export default TitleList;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 38px;
    letter-spacing: -0.95px;
    line-height: 52px;
    p {
      font-weight: 700;
      letter-spacing: -0.95px;
      &:last-child {
        display: none;
        ${(props) =>
          props.isMobile &&
          css`
            display: block;
          `}
      }
    }
  }
  ${(props) =>
    props.nonSubtitle &&
    css`
      span {
        font-size: 0;
        p {
          font-size: 38px;
        }
      }
    `}
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: 0;
    margin: ${(props) => props.margin};
    p {
      letter-spacing: -1.1px;
    }
    span {
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -1.1px;
      margin-bottom: 10px;
    }
  }
  ${(props) =>
    props.noneboldletter &&
    css`
      .bold {
        letter-spacing: 0;
      }
    `}
`;

const Number = styled.p`
  font-size: 100px;
  line-height: 100px;
  letter-spacing: -2.5px;
  margin-bottom: 82px;
  font-family: 'SF-Pro-Display', sans-serif;
  font-weight: bold;
  font-style: normal;
  ${(props) =>
    props.noneNum &&
    css`
      font-size: 0;
      line-height: 0;
      margin-bottom: 0 !important;
      display: none;
    `}
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0px;
    font-weight: bold;
    margin-bottom: 55px;
    margin-top: 5px;
  }
`;

const DescWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0 50px 0;
  span {
    line-height: 32px;
    letter-spacing: -0.5px;
  }
  ${(props) =>
    props.noneletter &&
    css`
      span {
        letter-spacing: 0px !important;
      }
    `}
  ${(props) =>
    props.noDesc &&
    css`
      display: none;
      padding: 0;
      span {
        line-height: 0;
      }
    `}
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: 0px 0 0 0;
    span {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.64px;
      font-weight: 400;
      margin: 0;
    }
    ${(props) =>
      props.nopadding &&
      css`
        padding: 0;
      `}
  }
`;
