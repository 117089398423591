import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { mobileContext } from './../../utils/mobileContext';
import MNavigation from './../mobile/MNavigation';

import * as utils from 'utils';
import { COMPANY_NAME_KO } from '../../utils/utilsConstant';

const Header = () => {
  // 전역변수 호출
  const { isMobile, setNavigationShow, setNavigationHide, isMain } = useContext(mobileContext);
  // 화면 이동을 위한 변수
  const navigate = useNavigate();
  // 모바일 화면에서 햄버거 버튼을 눌렀을때 네비게이션 show hide
  const handleShow = () => {
    setNavigationShow(true);
    setNavigationHide(false);
  };
  const { pathname } = useLocation();

  // 파일 다운로드
  const handleDownload = (fileName) => {
    const fileUrl = `/download/${fileName}`;
    const anchor = document.createElement('a');
    const newname = '바이크뱅크 뉴스레터 Vol.1.pdf';
    anchor.href = fileUrl;
    anchor.download = newname.normalize('NFC');
    anchor.click();
  };

  return (
    <HeaderStyle isMain={isMain} isMobile={isMobile}>
      <PageWidth>
        {/* 모바일 화면 */}
        {isMobile ? (
          <>
            <MNavigation />
            <h1 className="logo" onClick={() => navigate(`/`)}>
              BIKE BANK
            </h1>
            <Navigation>
              <button onClick={handleShow}></button>
            </Navigation>
          </>
        ) : (
          // 웹 화면
          <>
            <img src="/images/logo.png" alt={COMPANY_NAME_KO} onClick={() => navigate(`/`)} />
            <Navigation>
              <ul>
                <li>
                  <LinkStyle to="/company" isActive={pathname.includes('/company')}>
                    회사소개
                  </LinkStyle>
                </li>
                <li>
                  <LinkStyle to="/rent" isActive={pathname.includes('/rent')}>
                    렌트소개
                  </LinkStyle>
                </li>
                <li>
                  <LinkStyle to="/sale" isActive={pathname.includes('/sale')}>
                    차량판매
                  </LinkStyle>
                </li>
                <li>
                  <LinkStyle to="/customer" isActive={pathname.includes('/customer')}>
                    고객지원
                  </LinkStyle>
                </li>
                <li>
                  <LinkStyle onClick={() => handleDownload('news_letter.pdf')}>뉴스레터</LinkStyle>
                </li>
              </ul>
              <ul>
                <li>
                  <LinkStyle to="/recruit" isActive={pathname.includes('/recruit')}>
                    채용
                  </LinkStyle>
                </li>
                <li onClick={() => window.open(`https://blog.naver.com/gobikebank`)}>블로그</li>
              </ul>
            </Navigation>
          </>
        )}
      </PageWidth>
    </HeaderStyle>
  );
};

export default Header;

const HeaderStyle = styled.header`
  width: 100%;
  height: 100px;
  padding: 38px 0;
  border-bottom: 1px solid #fff;
  background-color: #fff;
  position: relative;
  display: block;
  .logo {
    background: url('/images/logo.png') no-repeat center;
    background-size: cover;
    width: 102px;
    height: 16px;
    font-size: 0;
    flex-shrink: 0;
  }
  ${(props) =>
    props.isMain &&
    css`
      position: fixed;
      top: 0;
      z-index: 99;
      border-bottom: 1px solid rgb(225, 225, 225);
    `};
  ${(props) =>
    props.isMobile &&
    css`
      position: fixed;
      top: 0;
      z-index: 99;
      border-bottom: 1px solid #fff;
    `};
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    height: 50px;
    padding: 0;
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
  }
`;

const PageWidth = styled.div`
  max-width: 1194px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 77px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: 0 30px;
    img {
      max-height: 13px;
    }
  }
`;

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ul {
    display: flex;
    gap: 84px;
    &:last-child {
      gap: 65px;
    }
    li {
      font-weight: 400;
      cursor: pointer;
      a {
      }
    }
  }
  button {
    background: url('/images/mobile_btn_menu.png') no-repeat center;
    background-size: auto 100%;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.tablet}) {
    ul {
      gap: 40px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    justify-content: flex-end;
    button {
      width: 19px;
      height: 14px;
      img {
        object-fit: contain;
      }
    }
  }
`;

const LinkStyle = styled(Link)`
  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 500;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        display: block;
        position: absolute;
        bottom: 0;
      }
    `}
`;
