import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// 구글 애널리틱스를 위해 url 변경을 감지하는 컴포넌트.
const RouteChangeTracker = () => {
  const location = useLocation(); // 현재 url의 정보를 받아온다.
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // localhost 로 진입한게 아니라면 구글애널리틱스 초기화 시킨다.
    if (
      !window.location.href.includes(
        process.env.REACT_APP_GOOGLE_ANALYTICS_INCLUDE_PATH,
      )
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default RouteChangeTracker;
