import { useEffect, useMemo, useState } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { mobileContext } from './utils/mobileContext';
import { ThemeProvider } from 'styled-components';

import * as page from './pages';
import * as component from './components';
import * as style from './styles';
import * as hook from './hooks';

function App() {
  // 전역 상태관리를 위한 변수들
  // 모바일 여부
  const [isMobile, setMobile] = useState();
  // 모바일 네비게이션 show/hide 여부
  const [navigationShow, setNavigationShow] = useState(false);
  const [navigationHide, setNavigationHide] = useState();
  // 현재 사용자의 스크롤 위치
  const [scrollPosition, setScrollPosition] = useState();

  // 사용자의 스크롤 위치 확인
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useMemo(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 사용자의 화면 너비
  const { width } = hook.useWindowDimensions();
  // 화면 너비에 따른 모바일 유무 확인
  useEffect(() => {
    width >= 1024 ? setMobile(false) : setMobile(true);
  }, [width]);

  // 현재 url 파라미터
  const { pathname } = useLocation();
  // 메인화면인지 유무
  const [isMain, setMain] = useState(pathname === '/' || pathname === '/recruit' ? true : false);

  useEffect(() => {
    pathname === '/' || pathname === '/recruit' ? setMain(true) : setMain(false); // pathname 에 따른 main 유무 확인
  }, [pathname]);

  // 사용자의 스크롤이 상단에 있는지 확인
  const [isTop, setIsTop] = useState();
  useEffect(() => {
    if (isMobile) {
      window.scrollY >= 300 ? setIsTop(true) : setIsTop(false);
    } else {
      window.scrollY >= 400 ? setIsTop(true) : setIsTop(false);
    }
    // eslint-disable-next-line
  }, [window.scrollY]);

  component.RouteChangeTracker(); // 구글애널리틱스

  return (
    // 전역변수를 담아놓은 contextAPI provider
    <mobileContext.Provider value={{ isMobile, setMobile, navigationShow, setNavigationShow, navigationHide, setNavigationHide, scrollPosition, isMain, setMain, isTop, setIsTop }}>
      {/* style theme */}
      <ThemeProvider theme={style.Theme}>
        {/* 공통 스타일 관리 */}
        <style.GlobalStyles />
        <Routes>
          {/* 공통 레이아웃 */}
          <Route path="/" element={<component.Layout />}>
            {/* 메인화면 */}
            <Route path="/" element={<page.Home />} />
            {/* 회사소개 페이지 */}
            <Route path="/company" element={<page.Company />} />
            <Route path="/company/:id" element={<page.Company />} />
            {/* 렌트소개 페이지 */}
            <Route path="/rent" element={<page.Rent />} />
            <Route path="/rent/:id" element={<page.Rent />} />
            {/* 렌트 인수 페이지 */}
            <Route path="/rent/insu" element={<page.RentRSC />} />
            {/* 차량판매 페이지 */}
            <Route path="/sale" element={<page.Sale />} />
            <Route path="/sale/:id" element={<page.Sale />} />
            {/* 차량판매 상세 페이지 */}
            <Route path="/sale/detail/:id" element={<page.SaleBike />} />
            {/* 고객지원 페이지 */}
            <Route path="/customer" element={<page.Customer />} />
            <Route path="/customer/:id" element={<page.Customer />} />
            {/* 채용 페이지 */}
            <Route path="/recruit" element={<page.Recruit />} />
            {/* 공지사항 페이지 */}
            <Route path="/notice/:id" element={<page.Notice />} />
            {/* 개인정보 페이지 */}
            <Route path="/privacy" element={<page.Privacy />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </mobileContext.Provider>
  );
}

export default App;
