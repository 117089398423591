import React from 'react';
import styled from 'styled-components';

import * as utils from '../../utils/company/companylist';

// swiper 사용을 위한 기본 import
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper';

const CompanySlide = () => {
  // swiper pagenation 을 위한 처리
  const pagination = {
    clickable: true, // 클릭 가능
    renderBullet: function (index, className) {
      // pagenation을 해당 페이지의 title 으로 지정
      return '<span class="' + className + '">' + utils.infraList[index].title + '</span>';
    },
  };
  return (
    <Wrap>
      <Swiper pagination={pagination} modules={[Pagination, Navigation, Autoplay]} navigation={true} loop autoplay={true}>
        {utils.infraList.map((item, index) => (
          // 각각의 slide 부분
          <SwiperSlide key={index}>
            <Content>
              <img src={item.imgsrc} alt={item.alt} />
              <p className="title">{item.title}</p>
              <p>{item.desc1}</p>
              <p>{item.desc2}</p>
            </Content>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrap>
  );
};

export default CompanySlide;

const Wrap = styled.div`
  width: 100%;
  padding: 30px 57px 30px 57px;
  box-shadow: 5px 0 30px 0 rgba(191, 191, 191, 0.16);
  border-radius: 15px;
  height: 600px;
  .swiper-pagination {
    width: 100%;
    display: flex;
    margin-top: 75px;
    position: relative;
    gap: 30px;
    z-index: 5;
  }
  .swiper-pagination-bullet {
    opacity: 1;
    font-size: 16px;
    color: #000;
    line-height: 13.3px;
    text-align: left;
    margin: 0 !important;
    background: none;
    width: auto;
    height: auto;
  }
  .swiper-pagination-bullet-active {
    background: none;
    color: ${(props) => props.theme.primary};
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 100%;
    transform: translateY(-60%);
  }
  .swiper-button-prev {
    left: auto;
    right: 80px;
    bottom: 0%;
    &:after {
      font-size: 0;
      background: url('/images/infra_left.png') no-repeat center;
      width: 18.2px;
      height: 30.6px;
      background-size: contain;
    }
  }
  .swiper-button-next {
    top: 100%;
    transform: translateY(-60%);
    &:after {
      font-size: 0;
      background: url('/images/infra_right.png') no-repeat center;
      width: 18.2px;
      height: 30.6px;
      background-size: contain;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  img {
  }
  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.45px;
    color: ${(props) => props.theme.gray02};
  }
  .title {
    color: black;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.6px;
    font-weight: 700;
    margin: 20px 0;
  }
`;
