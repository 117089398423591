import styled, { css } from 'styled-components';
import AOS from 'aos';
import { useEffect } from 'react';

const ImgList = ({ imgsrc, alt, title, desc1, desc2, desc3, width50, isApp, mobileImg, isMobile, aosDelay, googleUrl, appleUrl, noneletter }) => {
  useEffect(() => {
    // 스크롤 이벤트를 위한 초기설정
    AOS.init({ duration: 1800 });
  }, []);

  return (
    // width50 : width 가 33.3% 인것과 50% 인것이 있는데 50% 일때만 지정
    <List width50={width50} data-aos="fade-up" data-aos-delay={aosDelay} noneletter={noneletter}>
      {isMobile ? <img src={mobileImg} alt={alt} /> : <img src={imgsrc} alt={alt} />}
      <span>
        {title}
        <br />
        <p className="top">{desc1}</p>
        <p>{desc2}</p>
        <p>{desc3}</p>
      </span>
      {/* isApp : app 이동을 위한 button 이 필요한곳만 지정 */}
      <App isApp={isApp}>
        <button onClick={() => window.open(googleUrl)}></button>
        <button onClick={() => window.open(appleUrl)}></button>
      </App>
    </List>
  );
};

export default ImgList;

const List = styled.li`
  max-width: 33.3333%;
  width: 100%;
  box-shadow: 5px 0 30px 0 rgba(125, 125, 125, 0.16);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  img {
    width: 100%;
  }
  span {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.6px;
    font-weight: 700;
    padding: 20px 30px 20px 30px;
    display: block;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.45px;
      white-space: pre-wrap;
      color: ${(props) => props.theme.gray02};
    }
    .top {
      margin-top: 10px;
    }
  }
  ${(props) =>
    props.noneletter &&
    css`
      span {
        letter-spacing: 0px;
      }
    `}
  ${(props) =>
    props.width50 &&
    css`
      max-width: 50%;
      span {
        padding: 20px 57px 29px 57px;
        p {
          &:last-child {
            display: none;
          }
        }
      }
      @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
        overflow: hidden;
        img {
          height: 250px;
          object-fit: fill;
        }
        span {
          padding: 12px 20px 19px 20px;
        }
      }
    `}
  @media screen and (max-width: 1190px) {
    span {
      padding: 20px 0px 30px 30px;
      font-size: 22px;
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    span {
      padding: 20px 0px 30px 15px;
      font-size: 20px;
      p {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    width: 100%;
    min-width: 305px;
    margin-bottom: 30px;
    min-height: 320px;
    img {
      max-height: 220px;
      object-fit: cover;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.45px;
      padding: 18px 0px 10px 20px;
      .top {
        margin-top: 11px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.56px;
      }
    }
  }
`;

const App = styled.div`
  gap: 10px;
  align-items: center;
  margin: 0px 0 18px 36px;
  display: none;
  button {
    background: url('/images/btn_google.png') no-repeat center;
    width: 120px;
    height: 35px;
    background-color: #fff;
    box-shadow: 5px 0 10px 0 rgba(125, 125, 125, 0.16);
    border-radius: 4px;
    display: block;
    background-size: 75px auto;
    &:last-child {
      background: url('/images/btn_apple.png') no-repeat center;
      background-color: #fff;
      background-size: 72px auto;
    }
  }
  ${(props) =>
    props.isApp &&
    css`
      display: flex;
    `}
  @media screen and (max-width: 1190px) {
    margin: -10px 0 20px 20px;
  }
  @media screen and (max-width: 1000px) {
    gap: 14px;
    button {
      width: 113px;
      height: 33px;
      background-size: 78px auto;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    margin: 0px 0 17.5px 20px;
    button {
      background-size: 78px auto;
    }
  }
`;
