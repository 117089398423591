import React, { useContext, useEffect } from 'react';
import SubTitleSection from '../../components/common/SubTitleSection';
import * as homestyle from '../home/styles';
import * as style from './styles';
import * as utils from 'utils';
import { useParams, useNavigate } from 'react-router-dom';
import { mobileContext } from '../../utils';
import { COMPANY_NAME_KO } from '../../utils/utilsConstant';

const Notice = () => {
  // url의 id값을 가져온다.
  const { id } = useParams();
  // 눌렀을때 이동을 위한 변수지정
  const navigate = useNavigate();

  const { isMobile } = useContext(mobileContext);

  const NoticeList = [
    {
      id: 0,
      title: `${COMPANY_NAME_KO} 개인위치정보 이전 안내`,
      date: '2022.09.26',
      desc: `안녕하세요. ${COMPANY_NAME_KO}㈜입니다. ${COMPANY_NAME_KO}의 서비스를 이용해 주시는 고객님께 깊은 감사의 말씀을 드립니다.\n아래 개인정보 이전과 관련하여 안내말씀을 드립니다.\n\n당사는 2022년 10월 31일자로 인성데이타서비스㈜를 흡수합병하여 "${COMPANY_NAME_KO}㈜"로 새롭게 출발합니다.이에 따라, ${COMPANY_NAME_KO} 서비스를 이용 중이신 고객님께서, 기존과 동일한 서비스를 제공받으실 수 있도록, 서비스와 관련한 모든 개인정보가 "${COMPANY_NAME_KO}㈜"로 이전될 예정입니다.\n\n합병 후에도, ${COMPANY_NAME_KO}㈜는 개인정보보호법 등 관계법령을 준수하여, 기존과 동일하게 고객님의 개인정보를 이용할 예정입니다.또한 고객님의 개인정보를 안전하게 보호할 수 있도록, 최선을 다하겠습니다.개인정보 이전과 관련하여 문의사항이 있으시면 아래 전화번호 연락주시기 바랍니다.\n\n항상 고객의 입장에서 최선의 서비스를 제공하는 "${COMPANY_NAME_KO}㈜"가 되도록 노력하겠습니다.\n감사합니다.\n\n고객센터 : 1522-9008\n\n2022년 9월 26일\n\n대구광역시 달서구 성서공단로 11길 62, 5층(호산동, 대구융합알앤디센터)\n\n${COMPANY_NAME_KO} 주식회사`,
    },
    {
      id: 1,
      title: `${COMPANY_NAME_KO} 서류 양식 자료`,
      date: '2022.11.24',
      desc: '',
    },
    // {
    //   id: 2,
    //   title: `신규 렌트 상품 출시`,
    //   date: '2024.04.17',
    //   desc: '',
    // },
  ];

  useEffect(() => {
    // 이동했을때 스크롤 top
    window.scrollTo(0, 0);
  }, []);

  // const downloadFile = (fileUrl, fileName) => {
  //   const a = document.createElement('a');
  //   a.href = fileUrl;
  //   a.download = fileName;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(fileUrl);
  // };

  const handleDownload = (fileName, newname) => {
    const fileUrl = `/download/${fileName}`;
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = newname.normalize('NFC');
    anchor.click();
  };

  return (
    <>
      {/* 공통 헤더부분 */}
      <SubTitleSection title="공지사항" background="#d6d6d6" />

      {/* 내용 */}
      <homestyle.Section padding={isMobile ? '60px 0' : '153px 0'}>
        <homestyle.PageWidth gap="0">
          <style.Title>{NoticeList[id]?.title}</style.Title>
          <style.Date>{NoticeList[id]?.date}</style.Date>
          {Number(id) === 0 && (
            <style.Desc>
              <p>{NoticeList[0].desc}</p>
            </style.Desc>
          )}
          {Number(id) === 1 && (
            <style.Desc>
              <p>
                안녕하세요. {COMPANY_NAME_KO}㈜입니다.
                <br />
                <br />
                {COMPANY_NAME_KO}의 서비스를 이용해 주시는 고객님께 깊은 감사의 말씀을 드립니다.
                <br />
                더 편리한 서비스를 위해 상품 이용에 필요한 서류 양식을 안내드립니다.
                <br />
                <br />
                <span onClick={() => handleDownload(`insurance_age_change_request_0.2.pdf`, '보험연령변경요청서.pdf')}>[다운로드]</span> 1. 보험연령 변경 요청서 : 렌트상품 보험 연령을 변경할 때 작성하여 접수하는 양식
                <br />
                <br />
                <span onClick={() => handleDownload(`bike_transfer_request.pdf`, '차량이관요청서.pdf')}>[다운로드]</span> 2. 차량이관 요청서 : 렌트상품 이관 요청 시 작성하여 접수하는 양식
                <br />
                <br />
                <span onClick={() => handleDownload(`lost_documents.hwp`, '구비서류분실사유서.hwp')}>[다운로드]</span> 3. 구비서류분실사유서 : 명의이전서류 등 분실시 계약자의 동의가 불가할 경우, 재발급을 위해 제출하는 양식
                <br />
                <br />
                <span onClick={() => handleDownload(`business_change_agreement.hwp`, '사업자변경동의서.hwp')}>[다운로드]</span> 4. 사업자 변경동의서 : 계약점의 사업자변경시 변경된 사업자로 세금계산서 발행을 위해 제출하는 양식
                <br />
                <br />
                고객님들의 업무에 참고 부탁드립니다.
                <br />
                <br />
                감사합니다.
              </p>
            </style.Desc>
          )}
          {Number(id) === 2 && (
            <style.ImgWrap isMobile={isMobile}>
              <img src="/images/notice_2_detail_0.3.jpg" alt="바이크뱅크 렌트 프로모션" />
            </style.ImgWrap>
          )}
          <style.Button onClick={() => navigate('/customer/4')}>돌아가기</style.Button>
        </homestyle.PageWidth>
      </homestyle.Section>
    </>
  );
};

export default Notice;
