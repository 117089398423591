import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as homeStyle from '../home/styles';
import * as style from './styles';
import * as utils from '../../utils';
import * as component from '../../components';

import AOS from 'aos';
import { COMPANY_NAME_KO } from '../../utils/utilsConstant';

const Rent = () => {
  const { scrollPosition } = useContext(utils.mobileContext);
  // subnavigatio 의 dom 요소를 가져옴
  const pageRef = useRef([]);
  // rcs 보장범위 ref
  const rcsRef = useRef();
  // subnavigation의 정보
  const subNavList = [
    {
      listname: '이용안내',
      height: pageRef.current[0]?.getBoundingClientRect().top + window.pageYOffset - 100,
    },
    {
      listname: '상품안내',
      height: pageRef.current[1]?.getBoundingClientRect().top + window.pageYOffset - 100,
    },
    {
      listname: '보장안내',
      height: pageRef.current[2]?.getBoundingClientRect().top + window.pageYOffset - 100,
    },
    {
      listname: '서비스 안내',
      height: pageRef.current[3]?.getBoundingClientRect().top + window.pageYOffset - 100,
    },
  ];

  // 모바일 여부
  const { isMobile } = useContext(utils.mobileContext);
  // url 파라미터값 가져오기
  const { id } = useParams();
  // url 이동을 위한 변수
  const navigate = useNavigate();

  // 몇번째 탭을 누르고 있는지 알기위한 상태
  const [currentTab, setCurrentTab] = useState(0);
  const [serviceTab, setServiceTab] = useState(0);

  useEffect(() => {
    // 스크롤 이벤트를 위한 초기설정
    AOS.init({ duration: 1800 });

    // 경로에 따른 위치설정

    if (isMobile) {
      if (Number(id) === 3) {
        setTimeout(() => window.scrollTo(0, pageRef.current[2]?.getBoundingClientRect().top + window.pageYOffset - 100), 10);
      }
      if (Number(id) === 31) {
        setTimeout(() => window.scrollTo(0, rcsRef?.current.getBoundingClientRect().top + window.pageYOffset - 150), 10);
      }
    } else {
      if (Number(id) === 1) {
        setTimeout(() => window.scrollTo(0, pageRef.current[0]?.getBoundingClientRect().top + window.pageYOffset), 10);
      }
      if (Number(id) === 2) {
        setTimeout(() => window.scrollTo(0, pageRef.current[1]?.getBoundingClientRect().top + window.pageYOffset), 10);
      }
      if (Number(id) === 3) {
        setTimeout(() => window.scrollTo(0, pageRef.current[2]?.getBoundingClientRect().top + window.pageYOffset), 10);
      }
      if (Number(id) === 4) {
        setTimeout(() => window.scrollTo(0, pageRef.current[3]?.getBoundingClientRect().top + window.pageYOffset), 10);
      }
      if (Number(id) === 31) {
        setTimeout(() => window.scrollTo(0, rcsRef?.current.getBoundingClientRect().top + window.pageYOffset), 10);
      }
    }
    if (!id) return window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {/* 메타태그 */}
      <component.MetaTag
        title="렌트소개" //
        keywords="렌트소개"
        description="렌트소개"
        subject="렌트소개"
        url="http://www.bikebank.kr/rent"
      />

      {/* 공통 헤더부분 */}
      <component.SubTitleSection title="렌트소개" />
      <component.SubNavigation pageRef={pageRef} navlist={subNavList} />

      {/* 01. 렌트이용안내 */}
      <homeStyle.Section padding={isMobile ? '35px 0 100px 0' : '100px 0 220.5px 0'} ref={(el) => (pageRef.current[0] = el)}>
        <homeStyle.PageWidth gap={isMobile && '0'}>
          <component.TitleList number="01" subtitle="렌트 이용 안내" bold={!isMobile && '계약부터 신청까지 간편하게 이용하세요.'} desc1="*배달업 사업자전용" descright />

          {/* 웹일때만 이미지 보여줌 */}
          {!isMobile && (
            <div className="rentImg" data-aos="fade-up" data-aos-duration="1800">
              <img src="/images/rent_01.png" alt="렌트 이미지" />
            </div>
          )}

          <style.RentList isMobile={isMobile} data-aos="fade-up" data-aos-duration="1800">
            {utils.rentList01.map((item) => (
              <li key={item.id}>
                <img src={isMobile ? item.mobileimg : item.img} alt="숫자" />
                <p>
                  <span>{item.title}</span>
                  {isMobile ? item.mobiledesc : item.desc}
                </p>
              </li>
            ))}
          </style.RentList>
          {!isMobile && (
            <homeStyle.Button color={isMobile ? '#fff' : '#f8f8f8'} background={isMobile ? '#999' : '#434343'} isCenter={!isMobile} padding={isMobile ? '12px 0' : '19px 79px'} maxWidth={isMobile && '180px'} onClick={() => navigate('/customer/1')}>
              이용문의
            </homeStyle.Button>
          )}
          {isMobile && <style.Button onClick={() => navigate('/customer/1')}>이용문의</style.Button>}
        </homeStyle.PageWidth>
      </homeStyle.Section>

      {/* 02. 상품안내 */}
      <homeStyle.Section padding={isMobile ? '55px 0 100px 0' : '99px 0 196px 0'} ref={(el) => (pageRef.current[1] = el)} background={isMobile && '#f8f8f8'}>
        <homeStyle.PageWidth gap={isMobile && '0'}>
          <component.TitleList number="02" subtitle="상품 안내" bold="옵션에 대해 알려드립니다." desc1={!isMobile && '*상품 종류 및 보험 연령에 따라 요금이 변경됩니다.'} small />
          {isMobile && <p className="option_desc">*상품 종류 및 보험 연령에 따라 요금이 변경됩니다.</p>}

          {/* 모바일 유무에 따른 화면배치 변경 */}
          {isMobile ? (
            <component.RentOption data-aos="fade-up" />
          ) : (
            <>
              <style.RentOptionList data-aos="fade-up" data-aos-duration="1800">
                {utils.rentList02.map((item) => (
                  <li key={item.id} className={currentTab === item.id ? 'active' : ''} onClick={() => setCurrentTab(item.id)}>
                    {item.title}
                  </li>
                ))}
              </style.RentOptionList>
              <style.RnetOptionCont data-aos="fade-up" data-aos-duration="1800">
                <img src={utils.rentList02[currentTab].imgUrl} alt="렌트이미지" />
              </style.RnetOptionCont>
            </>
          )}
        </homeStyle.PageWidth>
      </homeStyle.Section>

      {/* 03. 사고보장 안내 */}
      <homeStyle.Section padding={isMobile ? '0px 0 0 0' : '99px 0 170px 0'} ref={(el) => (pageRef.current[2] = el)}>
        <homeStyle.PageWidth gap={isMobile && '0'} padding={isMobile && '0'}>
          {!isMobile && (
            <component.TitleList
              number="03"
              subtitle="사고 보장 안내" //
              bold={isMobile ? 'RCS (Rider Care System)' : `${COMPANY_NAME_KO}의 RCS (Rider Care System)`}
              desc1={!isMobile && `${COMPANY_NAME_KO}는 RCS를 통해 일 평균 30건의`}
              desc2={!isMobile && '크고 작은 배달이륜차 사고를 관리하고 있습니다.'}
            />
          )}
          {isMobile && (
            <style.RCSTitle>
              <p className="num">03</p>
              <p>
                사고 보장 안내
                <br />
                RCS <span>(Rider Care System)</span>
              </p>
            </style.RCSTitle>
          )}
          <style.RcsWrap>
            {/* RSC 사고 보장 안내 시작 */}
            <style.RscCont data-aos="fade-up" data-aos-duration="1800" data-aos-delay="0">
              <img src="/images/rcs_img_01.png" alt="RSC 이미지" />
              <div>
                <p>횡단보도 보행자 충돌 사고</p>
                <p>
                  경상남도 양산시 서창동 2차선 도로에서 라이더가
                  <br />
                  횡단보도를 보행하던 피해자와 충돌한 사고
                  <br />
                  자차 과실 100% / 피해자 상해등급 1급 / 후유장애 5급
                </p>
                <p>2020년 05월 19일</p>
              </div>
            </style.RscCont>
            <style.RscSection>
              <style.RscDesc data-aos="fade-up" data-aos-duration="1800" data-aos-delay="600">
                <p>일반 책임보험 차량 이용시</p>
                <p className="bold">
                  라이더 부담 금액 <br />총 74,144,696원
                </p>
                {isMobile ? <p>&#42;벌금 라이더 부담</p> : <p>&#60;벌금 라이더 부담&#62;</p>}
              </style.RscDesc>
              <style.RscDesc data-aos="fade-up" data-aos-duration="1800" data-aos-delay="800">
                <p className="primary">{COMPANY_NAME_KO} RCS 차량 이용시</p>
                <p className="primarybold">
                  {isMobile ? '양산시 김oo 지점장 부담 금액' : '양산시 김OO 지점장 부담 금액'}
                  <br />0 원
                </p>
                {isMobile ? <p>&#42;벌금 납부 후 지급</p> : <p>&#60;벌금 납부 후 지급&#62;</p>}
              </style.RscDesc>
            </style.RscSection>
          </style.RcsWrap>
        </homeStyle.PageWidth>
      </homeStyle.Section>

      {/* rsc 보장 범위 시작 */}
      <homeStyle.Section background={isMobile ? '#fff' : '#f8f8f8'} padding={isMobile ? '0 0 150px 0' : '104.5px 0 185.5px 0'}>
        <homeStyle.PageWidth gap={isMobile && '0'}>
          {isMobile ? <component.TitleList noneNum subtitle="RCS 보장 범위" noDesc /> : <component.TitleList noneNum nonSubtitle noDesc bold="RCS 보장 범위" />}
          {!isMobile && <p className="rscdesc">&lt;남자 30세 이상 유상보험 기준 비교&gt;</p>}
          {isMobile && <p className="mobile_rscdesc">{'종합보험 대비\n저렴한 비용, 폭 넓은 보장'}</p>}
        </homeStyle.PageWidth>
        <homeStyle.Area>
          {/* 모바일 유무에 따른 이미지 & 설명 변경 */}
          {isMobile ? (
            // 모바일일때
            <>
              <img src="/images/mobile_rent_rcs_02.png" alt="RSC 보장 범위" data-aos="fade-up" data-aos-duration="1800" />
              <p className="rscdesc">
                책임보험의 보상범위를 확대하여
                <br />
                <span>대인 무한, 대물 2억, 상해보험</span>까지 보장
              </p>
              <img src="/images/mobile_rent_rcs_03.png" alt="RSC 보장 범위" data-aos="fade-up" data-aos-duration="1800" />
              <img src="/images/mobile_rent_rcs_04.png" alt="RSC 보장 범위" data-aos="fade-up" data-aos-duration="1800" />
            </>
          ) : (
            // 웹일때
            <>
              <img src="/images/rcs_img_02.png" alt="RSC 보장 범위" data-aos="fade-up" data-aos-duration="1800" />
              <img src="/images/rcs_img_03.png" alt="RSC 보장 범위" data-aos="fade-up" data-aos-duration="1800" />
            </>
          )}
          {!isMobile && (
            <homeStyle.Button
              onClick={() => {
                sessionStorage.setItem('scroll_position', scrollPosition);
                navigate(`/rent/insu`);
              }}
              background={isMobile ? '#999999' : '#434343'}
              color="#fff"
              maxWidth="250px"
              padding={isMobile && '12px 37px'}
              mb="0"
            >
              보장 상세내용 확인
            </homeStyle.Button>
          )}
          {isMobile && (
            <style.Button
              onClick={() => {
                sessionStorage.setItem('scroll_position', scrollPosition);
                navigate('/rent/insu');
              }}
              style={{ marginTop: '20px' }}
            >
              보장 상세내용 확인
            </style.Button>
          )}
        </homeStyle.Area>
      </homeStyle.Section>

      {/* 실제 보장사례 토글 메뉴 */}
      <homeStyle.Section padding={isMobile ? '0 0 100px 0' : '126.5px 0 190px 0'} ref={rcsRef}>
        <homeStyle.PageWidth gap={isMobile && '0'} padding={isMobile && '0 30px'}>
          <component.TitleList noneNum noDesc subtitle={isMobile && '실제 보장사례'} bold={!isMobile && '실제 보장사례'} />
          <style.RscCaseList data-aos="fade-up" data-aos-duration="1800">
            {utils.rscCaseList.map((item) => (
              <component.RscCaseItem item={item} key={item.id} />
            ))}
          </style.RscCaseList>
        </homeStyle.PageWidth>
      </homeStyle.Section>

      {/* 04. 렌트차 서비스 안내 */}
      <homeStyle.Section padding={isMobile ? '55px 0 100px 0' : '99px 0 195px 0'} background={isMobile && '#f8f8f8'} ref={(el) => (pageRef.current[3] = el)}>
        <homeStyle.PageWidth>
          <component.TitleList number="04" bold={!isMobile && '렌트차 서비스 안내'} subtitle={isMobile && '렌트차 서비스 안내'} nonSubtitle={!isMobile} noDesc />
          {isMobile ? (
            <component.RentService />
          ) : (
            <React.Fragment>
              <style.RentOptionList width="50%" data-aos="fade-up" data-aos-duration="1800" gap="50px" height="100px" mt={!isMobile && '50px'}>
                {utils.rentList04.map((item) => (
                  <li key={item.id} className={item.id === serviceTab ? 'active' : ''} onClick={() => setServiceTab(item.id)}>
                    {item.title}
                  </li>
                ))}
              </style.RentOptionList>
              <style.RnetOptionCont data-aos="fade-up" data-aos-duration="1800" height="630px" mt={!isMobile && '-40px'}>
                <img src={utils.rentList04[serviceTab].imgUrl} alt="렌트 서비스 이미지" />
              </style.RnetOptionCont>
            </React.Fragment>
          )}
        </homeStyle.PageWidth>
      </homeStyle.Section>
    </>
  );
};
export default Rent;
