import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Top = styled.div`
  position: relative;
  width: 100%;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    .imgbox {
      /* height: 571px; */
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      img {
        max-width: 100%;

        /* margin-left: 3px; */
      }
    }
  }
`;

export const TextBox = styled.div`
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1194px;
  hr {
    width: 369px;
    margin: 37px 0 25px 0;
  }
  p {
    color: #fff;
    &.title {
      font-family: 'SF-Pro-Display' !important;
      font-weight: 600;
      font-size: 52px;
      margin-bottom: 10px;
    }
    &.desc {
      letter-spacing: -1.08px;
      line-height: 26px;
      font-size: 18px;
    }
    &.price {
      line-height: 30px;
      font-size: 24px;
      font-family: 'SF-Pro-Display' !important;
      font-weight: 600;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    bottom: 100px;
    hr {
      width: calc(100% - 190px);
      margin: 17px 0 20px 0;
    }
    p {
      color: #fff;
      &.title {
        font-family: 'SF-Pro-Display' !important;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 0px;
      }
      &.desc {
        letter-spacing: -1.08px;
        line-height: 26px;
        font-size: 18px;
      }
      &.price {
        line-height: 28px;
        font-size: 22px;
        font-family: 'SF-Pro-Display' !important;
        font-weight: 600;
      }
    }
  }
`;

export const DesignTitleBox = styled.div`
  .title {
    letter-spacing: -0.5px;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    line-height: 29px;
  }
  .desc {
    font-size: 38px;
    line-height: 55px;
    letter-spacing: -0.95px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-top: 35px;
    .desc {
      font-size: 32px;
      line-height: 46px;
    }
  }
`;

export const DesignBox = styled.ul`
  display: flex;
  gap: 19px;
  margin-top: 106px;
  width: 100%;
  li {
    font-size: 0;
    margin-top: 36px;
    &:nth-child(2) {
      margin: 0;
    }
    img {
      max-width: 100%;
    }
    p {
      font-weight: 600;
      letter-spacing: -0.5px;
      font-size: 20px;
      line-height: 29px;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-top: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    li {
      width: 100%;
      margin-top: 0;
      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
`;

export const TitleBox = styled.div`
  margin-top: 130px;
  display: flex;
  justify-content: space-between;
  p {
    font-size: 38px;
    line-height: 52px;
    letter-spacing: -0.95px;
    span {
      display: block;
      font-size: 38px;
      line-height: 52px;
      letter-spacing: -0.95px;
      font-weight: 600;
    }
  }
  div {
    p {
      font-size: 26px;
      line-height: 37px;
    }
  }
  ul {
    display: flex;
    gap: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-top: 123px;
    flex-direction: column;
    p {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.55;
      span {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -0.55;
      }
    }
  }
`;

export const ColorBox = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.4px;
    font-family: 'NanumSquare';
    font-weight: 600;
  }
`;

export const ColorList = styled.ul`
  display: flex;
  gap: 12px;
`;

export const ColorItem = styled.li`
  width: 45px;
  height: 45px;
  background-color: ${({ color }) => color};
  border-radius: 45px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 30px;
    height: 30px;
  }
`;

export const OptionBox = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin: 70px 0 0 0;
  ul {
    width: 50%;
    border-top: 2px solid #000;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    li {
      p {
        font-size: 20px;
        /* letter-spacing: -0.4px; */
        display: flex;
        font-weight: 100;
        span {
          font-weight: 600;
          font-size: 20px;
          letter-spacing: -0.4px;
          display: block;
          width: 155px;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    flex-direction: column;
    margin-top: 30px;
    gap: 0;
    ul {
      width: 100%;
      gap: 10px;
      border-top: 1px solid #000;
      padding: 40px 0 0 0;
      &:last-child {
        border: none;
        padding: 40px 0 40px 0;
      }
      li {
        p {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.4px;
          font-family: 'NanumSquare';
          font-weight: 400;
          span {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: -0.4px;
            font-family: 'NanumSquare';
            font-weight: 700;
            width: 36%;
          }
        }
      }
    }
  }
`;

export const SwiperWrap = styled.div`
  margin: 154px 0 260px 0;
  img {
    max-height: 765px;
  }
  .swiper-button-next {
    background: url('/images/sale/detail/icon_right.png') no-repeat;
    background-size: 50px 50px;
    background-position: center;
  }

  .swiper-button-prev {
    background: url('/images/sale/detail/icon_left.png') no-repeat;
    background-size: 50px 50px;
    background-position: center;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin: 60px 0 110px 0;
    img {
      max-height: 550px;
    }
  }
  @media screen and (max-width: 700px) {
    img {
      max-height: 450px;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      max-height: 350px;
    }
  }
  @media screen and (max-width: 500px) {
    img {
      max-height: 300px;
    }
  }
  @media screen and (max-width: 400px) {
    img {
      max-height: 250px;
    }
  }
`;

export const SwiperItem = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GalleryTitle = styled.p`
  font-size: 38px;
  line-height: 52px;
  letter-spacing: -0.95px;
  margin-bottom: 45px;
  font-weight: 600;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.55px;
  }
`;

export const GalleryWrap = styled(Swiper)`
  margin-bottom: 30px;
  .swiper-container {
    height: 573px; // 원하는 높이 값
  }
  img {
    max-height: 573px;
    max-width: 100%;
  }
`;

export const CustomerWrap = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 184px 0 178px 0;
  gap: 57px;
  p {
    text-align: center;
    font-size: 38px;
    line-height: 52px;
    letter-spacing: -0.95px;
  }
  button {
    border: 2px solid black;
    text-align: center;
    font-size: 42px;
    line-height: 80px;
    border-radius: 10px;
    width: 306px;
    font-weight: 600;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: 100px 0;
    p {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.55px;
    }
    button {
      font-size: 23px;
      line-height: 44px;
      border-radius: 10px;
      width: 170px;
    }
  }
`;
