import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ReactGA from 'react-ga'; // 구글 애널리틱스 사용을 위한 패키지

import { BrowserRouter } from 'react-router-dom'; //react-router-dom 사용을 위한 provider
import { QueryClient, QueryClientProvider } from 'react-query'; //react-query 사용을 위한 provider
import { HelmetProvider } from 'react-helmet-async'; //react-helmet 사용을 위한 provider

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; // 구글애널리틱스 id 불러오기

ReactGA.initialize(TRACKING_ID); // 구글애널리틱스 초기화

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </HelmetProvider>
);

reportWebVitals();
