import * as utils from 'utils';
import { COMPANY_NAME_KO } from '../utilsConstant';

// 회사소개 페이지 util

// 회사소개 01번
export const companyList01 = [
  {
    imgsrc: '/images/company_list_01_01.png',
    mobileimg: '/images/mobile_company_list_01_01.png',
    alt: '주행 경험',
    title: '주행 경험',
    desc1: '차량 뿐만 아니라 서비스와 인프라까지\n고객의 입장에서 이륜차 주행 경험을\n고민하여 솔루션을 제공하고자 합니다.',
    mobiledesc: '차량 뿐만 아니라 서비스와 인프라까지 고객의\n입장에서 이륜차 주행 경험을 고민하여 솔루션을\n제공하고자 합니다.',
  },
  {
    imgsrc: '/images/company_list_01_02.png',
    mobileimg: '/images/mobile_company_list_01_02.png',
    alt: '기술과 시스템',
    title: '기술과 시스템',
    desc1: '전자 계약 시스템과 APP을 이용하여\n계약부터 서비스 이용까지 모든 업무가\n편리해질 수 있도록 노력하고 있습니다.',
    mobiledesc: '전자 계약 시스템과 APP을 이용하여 계약부터\n서비스 이용까지 모든 업무가 편리해질 수\n있도록 노력하고 있습니다.',
  },
  {
    imgsrc: '/images/company_list_01_03.png',
    mobileimg: '/images/mobile_company_list_01_03.png',
    alt: '서비스 인프라',
    title: '서비스 인프라',
    desc1: '고객 중심의 차량 관리와 사고 수리를\n어디에서나 지원 받으며 모두가 믿고 이용할\n수 있는 서비스를 만들고자 합니다.',
    mobiledesc: '고객 중심의 차량 관리와 사고 수리를\n어디에서나 지원 받으며 모두가 믿고 이용할 수\n있는 서비스를 만들고자 합니다.',
  },
];

// 핵심 경쟁력
export const companyInfra = [
  {
    id: 1,
    title: '인프라 & 네트워크',
    desc: '국내 전역에 서비스 네트워크와\n물류망을 보유하고 있습니다.',
    mobiledesc: '국내 전역에 서비스 네트워크와\n물류망을 보유하고 있습니다.',
  },
  {
    id: 2,
    title: '이륜차 전문성',
    desc: '국내 최고 수준의 이륜차 전문 인력으로\n제조, 물류, 정비, 운영, 영업 등 분야별 전문가와 함께합니다.',
    mobiledesc: '국내 최고 수준의 이륜차 전문 인력으로\n제조, 물류, 정비, 운영, 영업 등 분야별\n전문가와 함께합니다.',
  },
  {
    id: 3,
    title: 'RCS 조직',
    desc: '보험보장 전담 조직을 통하여\n이륜차에 최적화 된 RCS 보장을 제공합니다.',
    mobiledesc: '보험보장 전담 조직을 통하여\n이륜차에 최적화 된 RCS 보장을 제공합니다.',
  },
  {
    id: 4,
    title: '운영 노하우',
    desc: '전국 파트너를 대상으로 주문부터 관리까지\n다양한 노하우를 보유하고 있습니다.',
    mobiledesc: '전국 파트너를 대상으로 주문부터 관리까지\n다양한 노하우를 보유하고 있습니다.',
  },
];

// 도전과 성과
export const companyChallenge = [
  {
    id: 1,
    date: '2021.',
    desc: '매출 700억 달성\n서비스 네트워크 300점 돌파\n딜버 업무협약 체결\n콜25 업무협약 체결\n만나플러스 업무협약 체결\n디플러스 업무협약 체결\n푸드바이크 업무협약 체결\n고고라이더 업무협약 체결\n예스런 업무협약 체결',
  },
  {
    id: 2,
    date: '2020.',
    desc: '매출 400억 달성\n시장 점유율 1위 달성\nDB손해보험 업무협약 체결\nDB자동차보험손해사정 업무협약 체결\n바로고 업무협약 체결\n모아콜 업무협약 체결\n고고로 전기이륜차 충전스테이션\n테스트 시작',
  },
  {
    id: 3,
    date: '2019.',
    desc: `생각대로에 렌트차량 공급\n배달 맞춤형 이륜차 렌트상품 출시\n ${COMPANY_NAME_KO} 법인 설립`,
  },
];

// 회사소개 02번
export const companyList02 = [
  {
    imgsrc: '/images/tech_01.png',
    mobileimg: '/images/mobile_main_tech_01.png',
    alt: '편리한 계약',
    title: '편리한 계약',
    desc1: '별도의 방문 없이 공급계약이 가능하며 간단한',
    desc2: '휴대폰 인증으로 전자 계약을 진행할 수 있습니다.',
  },
  {
    imgsrc: '/images/tech_02.png',
    mobileimg: '/images/mobile_main_tech_02.png',
    alt: '안전한 인증',
    title: '안전한 인증',
    desc1: '장소 및 시간에 상관없이 계약서 확인이 가능하며',
    desc2: '서명 시점 확인 및 위변조를 방지합니다.',
  },
];

// 차량 신청과 관리
export const companyList03 = [
  {
    imgsrc: '/images/tech_02_01.png',
    mobileImg: '/images/mobile_app_01.png',
    alt: '렌트차 신청',
    title: '렌트차 신청',
    desc1: '추가 라이더 모집으로 배달용 오토바이가',
    desc2: '필요할 때 차량 공유 앱을 통해 손쉽게 렌트',
    desc3: '차량을 신청해 보세요.',
    mobiledesc: '추가 라이더 모집으로 배달용 오토바이가 필요할 \n때 차량 공유 앱을 통해 손쉽게 렌트 차량을 \n신청해 보세요.',
    isApp: true,
    googleUrl: 'https://play.google.com/store/apps/details?id=com.bikebank.sharebike',
    appleUrl: 'https://apps.apple.com/kr/app/%EB%B0%94%EC%9D%B4%ED%81%AC%EB%B1%85%ED%81%AC-%EC%B0%A8%EB%9F%89%EA%B3%B5%EC%9C%A0/id1644014033',
  },
  {
    imgsrc: '/images/tech_02_02.png',
    mobileImg: '/images/mobile_app_02.png',
    alt: '차량관리',
    title: '차량관리',
    desc1: '정기 점검에서 사고 수리까지 체계적으로',
    desc2: '차량을 관리하는 방법. 믿을 수 있는 보증',
    desc3: '서비스를 제공합니다.',
    mobiledesc: '정기 점검에서 사고 수리까지 체계적으로 차량을 \n관리하는 방법. 믿을 수 있는 보증 서비스를 \n제공합니다',
    isApp: true,
    googleUrl: 'https://play.google.com/store/apps/details?id=com.bikebank.bbm',
    appleUrl: 'https://apps.apple.com/kr/app/bbm-bikebank-membership/id1568643930',
  },
  {
    imgsrc: '/images/tech_02_03.png',
    mobileImg: '/images/mobile_app_03.png',
    alt: '소모품관리',
    title: '소모품관리',
    desc1: '안전한 라이딩을 위해 주기적으로 교환하는',
    desc2: `소모품. ${COMPANY_NAME_KO} 소모품 앱을 통해`,
    desc3: '가까운 정비점에서 관리받으세요.',
    isApp: true,
    mobiledesc: `안전한 라이딩을 위해 주기적으로 교환하는 \n소모품. ${COMPANY_NAME_KO} 소모품 앱을 통해 가까운 \n정비점에서 관리받으세요.`,
    googleUrl: 'https://play.google.com/store/apps/details?id=com.gobikebank.customer',
    appleUrl: 'https://apps.apple.com/kr/app/%EB%B0%94%EC%9D%B4%ED%81%AC%EB%B1%85%ED%81%AC/id1643443056',
  },
];

// 모바일 회사소개 03번
export const mobileCompanyList = [
  {
    imgsrc: '/images/mobile_infra_01.png',
    alt: '차량출고',
    title: '차량출고',
    desc1: '주행이 바로 가능하도록 차량을 세팅하고',
    desc2: '전국 각지로 배송합니다.',
  },
  {
    imgsrc: '/images/mobile_infra_02.png',
    alt: '렌트운영',
    title: '렌트운영',
    desc1: '이륜차를 이용하고 관리하는데 필요한 운영',
    desc2: '업무에 대해 빠르고 정확하게 대응을 진행합니다.',
  },
  {
    imgsrc: '/images/mobile_infra_03.png',
    alt: '소모품교환',
    title: '소모품교환',
    desc1: '배달용 오토바이는 안전한 주행을 위해',
    desc2: '주기적으로 소모품을 교환해줘야 합니다.',
  },
  {
    imgsrc: '/images/mobile_infra_04.png',
    alt: '차량수리',
    title: '차량수리',
    desc1: '전국 서비스망을 통해 차량의 수리를 신속하게',
    desc2: '진행하고 안전하고 편리한 주행을 지원합니다.',
  },
  {
    imgsrc: '/images/mobile_infra_05.png',
    alt: '사고보장',
    title: '사고보장',
    desc1: '보다 안전하게 이륜차를 사용하기 위해서 보험 및',
    desc2: '보장으로 사고에 대비하는 것은 필수적입니다.',
  },
  {
    imgsrc: '/images/mobile_infra_06.png',
    alt: '차량수거',
    title: '차량수거',
    desc1: '사용이 끝난 이륜차를 안전하게 수거하여 필요에',
    desc2: '따라 리사이클 및 파츠별로 분해하게 됩니다.',
  },
];

// 회사소개 03번 슬라이드
export const infraList = [
  {
    imgsrc: '/images/infra_img_01.png',
    title: '차량출고',
    alt: '인프라 이미지',
    desc1: '주행이 바로 가능하도록 차량을 세팅하고',
    desc2: '전국 각지로 배송합니다.',
  },
  {
    imgsrc: '/images/infra_img_02.png',
    title: '렌트운영',
    alt: '인프라 이미지',
    desc1: '이륜차를 이용하고 관리하는데 필요한 운영 업무에 대해',
    desc2: '빠르고 정확하게 대응을 진행합니다.',
  },
  {
    imgsrc: '/images/infra_img_03.png',
    title: '소모품교환',
    alt: '인프라 이미지',
    desc1: '일 평균 8시간 이상 운행되는 배달용 오토바이는 안전한 주행을 위해',
    desc2: '주기적으로 소모품을 교환해줘야 합니다.',
  },
  {
    imgsrc: '/images/infra_img_04.png',
    title: '차량수리',
    alt: '인프라 이미지',
    desc1: '전국 서비스망을 통해 차량의 수리를 신속하게 진행하고',
    desc2: '안전하고 편리한 주행을 지원합니다.',
  },
  {
    imgsrc: '/images/infra_img_05.png',
    title: '사고보장',
    alt: '인프라 이미지',
    desc1: '보다 안전하게 이륜차를 사용하기 위해서 보험 및 보장으로',
    desc2: '사고에 대비하는 것은 필수적입니다.',
  },
  {
    imgsrc: '/images/infra_img_06.png',
    title: '차량수거',
    alt: '인프라 이미지',
    desc1: '사용이 끝난 이륜차를 안전하게 수거하여',
    desc2: '필요에 따라 리사이클 및 파츠별로 분해하게 됩니다.',
  },
];
