import * as utils from 'utils';
import { COMPANY_NAME_KO } from '../utilsConstant';
// 메인 페이지 utils
export const NoticeList = [
  {
    id: 1,
    type: '추가연령',
    desc: '만 35세\n렌트상품 출시',
  },
  {
    id: 2,
    type: '신규상품',
    desc: '비유상\n렌트상품 출시',
  },
  { id: 3, type: '신규모델', desc: '고고로\n렌트상품 접수' },
];

export const SolutionList = [
  {
    img: '/images/main/img_solution_01.png',
    mobileimg: '/images/main/mobile_img_solution_01.png',
    alt: '이륜차 렌트',
    title: '이륜차 렌트',
  },
  {
    img: '/images/main/img_solution_02.png',
    mobileimg: '/images/main/mobile_img_solution_02.png',
    alt: '이륜차 판매',
    title: '이륜차 판매',
  },
  {
    img: '/images/main/img_solution_03.png',
    mobileimg: '/images/main/mobile_img_solution_03.png',
    alt: '서비스 네트워크',
    title: '서비스 네트워크',
  },
];

// checkpoint
export const checkpoint_list = [
  {
    id: 0,
    number: '01.',
    title: '보험보장',
    desc: '합리적인 가격과 폭 넓은 보장 범위',
  },
  {
    id: 1,
    number: '02.',
    title: '관리편의성',
    desc: '이륜차에 특화된 관리 서비스',
  },
  {
    id: 2,
    number: '03.',
    title: '렌트비용',
    desc: '낮은 비용으로 사업 부담 감소',
  },
];

// 보장범위
export const insurance_coverage = [
  {
    id: 0,
    title: '대인보장',
    desc: '무 한',
  },
  {
    id: 1,
    title: '대물보장',
    desc: '2억원',
  },
  {
    id: 2,
    title: '라이더 추가보장',
    desc: '상해보험',
  },
];

// 보장사례
export const insurance_example = [
  {
    id: 0,
    title: `${COMPANY_NAME_KO} RCS 이용시`,
    boldtitle: '7,400만원',
    subtitle: '\n보상 받은 사연',
    desc: '횡단보도 보행자 충돌 사고',
    url: '/rent/3',
  },
  {
    id: 1,
    title: '책임보험만 가입시',
    boldtitle: '3,100만원',
    subtitle: '\n부담 한 사연',
    desc: '무단횡단 피해자와 충돌 사고',
    url: '/rent/31',
  },
];
