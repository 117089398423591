import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import * as utils from 'utils';
import { COMPANY_NAME_KO, COMPANY_NAME_EN } from '../../utils/utilsConstant';

const MetaTag = ({ title, description, url }) => {
  const location = useLocation();

  return (
    <Helmet>
      {/* 메인화면일때만 다름 */}
      {location.pathname === '/' || location.pathname === '/privacy' ? (
        <>
          <title>(주)바이크뱅크에 오신것을 환영합니다.</title>

          <meta name="keywords" content="이륜차, 오토바이, 오토바이렌트, 오토바이리스, 배달오토바이, 배달대행렌트, 배달대행오토바이, 라이더" />
          <meta name="description" content="비즈니스 이륜차 솔루션 바이크뱅크의 공식 홈페이지입니다." />
          <meta name="author" content={COMPANY_NAME_KO} />
          <meta name="subject" content={`${COMPANY_NAME_KO} 홈페이지`} />
          <meta name="copyright" content={`Copyrights 2020 ${COMPANY_NAME_EN}`} />

          {/* ogtag 관련 */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`:: ${COMPANY_NAME_KO} :: - ${title}`} />
          <meta property="og:site_name" content={`:: ${COMPANY_NAME_KO} :: - ${title}`} />
          <meta property="og:description" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta property="og:image" content={'/images/snail'} />
          <meta property="og:url" content="http://www.bikebank.kr" />

          <link rel="canonical" href="http://www.bikebank.kr" />
        </>
      ) : // 차량판매 일때
      location.pathname.includes('/sale/detail') ? (
        <>
          <title>{`:: 신차소개 - ${title} ::`}</title>
          <meta name="keywords" content={`신차소개 ${description}`} />
          <meta name="description" content={`신차소개 ${description}`} />
          <meta name="author" content={COMPANY_NAME_KO} />
          <meta name="subject" content={`신차소개 ${description}`} />
          <meta name="copyright" content={`Copyrights 2020 ${COMPANY_NAME_EN}`} />

          {/* ogtag 관련 */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`:: ${COMPANY_NAME_KO} - ${title} ::`} />
          <meta property="og:site_name" content={`:: ${COMPANY_NAME_KO} - ${title} ::`} />
          <meta property="og:description" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta property="og:image" content={'/images/snail'} />
          <meta property="og:url" content={url} />

          <link rel="canonical" href={url} />
        </>
      ) : (
        <>
          <title>{`:: ${COMPANY_NAME_KO} - ${title} ::`}</title>
          <meta name="keywords" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta name="description" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta name="author" content={COMPANY_NAME_KO} />
          <meta name="subject" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta name="copyright" content={`Copyrights 2020 ${COMPANY_NAME_EN}`} />

          {/* ogtag 관련 */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`:: ${COMPANY_NAME_KO} - ${title} ::`} />
          <meta property="og:site_name" content={`:: ${COMPANY_NAME_KO} - ${title} ::`} />
          <meta property="og:description" content={`${COMPANY_NAME_KO} ${description}`} />
          <meta property="og:image" content={'/images/snail'} />
          <meta property="og:url" content={url} />

          <link rel="canonical" href={url} />
        </>
      )}
    </Helmet>
  );
};

export default MetaTag;
